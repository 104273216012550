import { ApiEndpoint, DashboardEndpoint, FormType, UserEndpoint, WorkspaceMemberEndpoint } from '@enums';
import { checkResponseCertificateStatus, transformWorkspaceStatus } from '@utils';

export const noop = <T>(data: T) => data;

type URLPath = `/${string}`;
type TranslationKey = `${'form' | 'configuration' | 'workspace'}:${string}`;

interface Endpoint {
  path: URLPath;
  endPath?: URLPath;
  translation?: TranslationKey;
  mockResponse?: any;
  formatData?: (data: any) => any;
}

interface FormConfiguration {
  name: string;
  read: {
    default: Endpoint;
    [key: string]: Endpoint;
  };
  write: {
    default: Endpoint;
    [key: string]: Endpoint;
  };
}

export const universalFormConfig = (key: FormType): FormConfiguration => {
  const configurations: Record<string, FormConfiguration> = {
    [FormType.User]: {
      name: 'user',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/user/login',
          translation: 'form:toast.user',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/user/login',
          translation: 'form:toast.user',
        },
        [UserEndpoint.REGISTER]: {
          path: '/user/register',
          translation: 'form:toast.user',
        },
        [UserEndpoint.VERIFY_EMAIL]: {
          path: '/user/verify-email',
          translation: 'form:toast.user',
        },
      },
    },
    [FormType.Cdn]: {
      name: 'cdn',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/application',
          translation: 'configuration:toasts.cdn',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/application',
          translation: 'configuration:toasts.cdn',
        },
        [ApiEndpoint.DEPLOY]: {
          path: '/cdn/deploy',
          translation: 'form:toasts.cdn',
        },
      },
    },
    [FormType.Workspace]: {
      name: 'workspace',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/workspace',
          translation: 'form:toasts.workspace',
        },
        [ApiEndpoint.CONFIGURATION]: {
          path: '/workspace',
          translation: 'form:toasts.workspace',
          formatData: transformWorkspaceStatus,
        },
        [WorkspaceMemberEndpoint.JOIN]: {
          path: '/workspace',
          endPath: '/join',
          translation: 'form:toasts.workspace',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/workspace',
          translation: 'form:toasts.workspace',
        },
        [ApiEndpoint.CONFIGURATION]: {
          path: '/workspace',
          translation: 'workspace:toasts',
          formatData: transformWorkspaceStatus,
        },
        [WorkspaceMemberEndpoint.TRANSFER]: {
          path: '/workspace',
          endPath: '/transfer',
          translation: 'workspace:toasts',
          formatData: transformWorkspaceStatus,
        },
        [WorkspaceMemberEndpoint.MEMBER]: {
          path: '/workspace',
          endPath: '/user',
          translation: 'workspace:toasts',
          formatData: transformWorkspaceStatus,
        },
        [WorkspaceMemberEndpoint.ACCEPT]: {
          path: '/workspace',
          endPath: '/accept',
          translation: 'workspace:toasts',
        },
        [WorkspaceMemberEndpoint.RESEND_INVITATION]: {
          path: '/workspace',
          endPath: '/resend_invitation',
          translation: 'workspace:toasts',
          formatData: transformWorkspaceStatus,
        },
        [WorkspaceMemberEndpoint.JOIN]: {
          path: '/workspace',
          endPath: '/join',
          translation: 'workspace:toasts',
        },
      },
    },
    [FormType.CdnDashboard]: {
      name: 'cdn_dashboard',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/metric/request_by_country',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.QPS]: {
          path: '/cdn/metric/qps',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.REQUEST_PER_IP]: {
          path: '/cdn/metric/request_per_ip',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.TOP_TEN_URL]: {
          path: '/cdn/metric/top_request_url',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.ATTACK_COUNT]: {
          path: '/cdn/metric/attack_count',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.CACHE]: {
          path: '/cdn/metric/cache_ratio',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.TOTAL_TRAFFIC]: {
          path: '/cdn/metric/traffic_size',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.ERROR_LOG]: {
          path: '/cdn/log/error_log',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.TRAFFIC_LOG]: {
          path: '/cdn/log/access_log',
          translation: 'configuration:toasts.dashboard',
        },
        [DashboardEndpoint.ATTACK_LOG]: {
          path: '/cdn/log/dos',
          translation: 'configuration:toasts.dashboard',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/metric/request_by_country',
          translation: 'configuration:toasts.dashboard',
        },
      },
    },
    [FormType.CdnDomain]: {
      name: 'cdn_domain',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/domain',
          translation: 'configuration:toasts.domain',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/domain',
          translation: 'configuration:toasts.domain',
        },
        [ApiEndpoint.VERIFY]: {
          path: '/cdn/domain-verify',
          translation: 'configuration:toasts.domain',
        },
      },
    },

    [FormType.CdnOrigin]: {
      name: 'cdn_origin',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/origin',
          translation: 'configuration:toasts.origin',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/origin',
          translation: 'configuration:toasts.origin',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/origin',
          translation: 'configuration:toasts.origin',
        },
      },
    },
    [FormType.CdnRoute]: {
      name: 'cdn_route',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/route',
          translation: 'configuration:toasts.route',
        },
        [ApiEndpoint.SORTING]: {
          path: '/cdn/route/list',
          translation: 'configuration:toasts.route',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/route',
          translation: 'configuration:toasts.route',
        },
        [ApiEndpoint.SORTING]: {
          path: '/cdn/route/sort',
          translation: 'configuration:toasts.route',
        },
      },
    },
    [FormType.CdnSSL]: {
      name: 'cdn_ssl',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/ssl',
          formatData: checkResponseCertificateStatus,
          translation: 'configuration:toasts.ssl',
        },
        [ApiEndpoint.LOG]: {
          path: '/cdn/ssl',
          endPath: '/acme_log',
          translation: 'configuration:toasts.ssl',
        },
        [ApiEndpoint.SORTING]: {
          path: '/cdn/ssl/list',
          translation: 'configuration:toasts.ssl',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/ssl',
          translation: 'configuration:toasts.ssl',
        },
        [ApiEndpoint.SORTING]: {
          path: '/cdn/ssl/sort',
          translation: 'configuration:toasts.ssl',
        },
      },
    },
    [FormType.CdnSecurity]: {
      name: 'cdn_security',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-security-rules',
          translation: 'configuration:toasts.predefinedSecurityRules',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-security-rules',
          translation: 'configuration:toasts.securityRules',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-security-rules',
          translation: 'configuration:toasts.predefinedSecurityRules',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-security-rules',
          translation: 'configuration:toasts.securityRules',
        },
      },
    },
    [FormType.CdnWAF]: {
      name: 'cdn_waf',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-waf',
          translation: 'configuration:toasts.waf',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-waf',
          translation: 'configuration:toasts.customWaf',
        },
        [ApiEndpoint.WHITELIST]: {
          path: '/cdn/whitelist-waf',
          translation: 'configuration:toasts.whitelistWaf',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-waf',
          translation: 'configuration:toasts.waf',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-waf',
          translation: 'configuration:toasts.customWaf',
        },
        [ApiEndpoint.WHITELIST]: {
          path: '/cdn/whitelist-waf',
          translation: 'configuration:toasts.whitelistWaf',
        },
      },
    },
    [FormType.CdnPageRules]: {
      name: 'cdn_page_rules',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-page-rules',
          translation: 'configuration:toasts.predefinedPageRules',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-page-rules',
          translation: 'configuration:toasts.pageRules',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-page-rules',
          translation: 'configuration:toasts.predefinedPageRules',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-page-rules',
          translation: 'configuration:toasts.pageRules',
        },
      },
    },
    [FormType.CdnCache]: {
      name: 'cdn_cache',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-cache',
          translation: 'configuration:toasts.predefinedCache',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-cache',
          translation: 'configuration:toasts.customCache',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/predefined-cache',
          translation: 'configuration:toasts.predefinedCache',
        },
        [ApiEndpoint.CUSTOM]: {
          path: '/cdn/custom-cache',
          translation: 'configuration:toasts.customCache',
        },
        [ApiEndpoint.CLEAR]: {
          path: '/cdn/cache/clear-all',
          translation: 'configuration:toasts.predefinedCache',
        },
      },
    },
    [FormType.CdnApplicationConfiguration]: {
      name: 'cdn_app_configuration',
      read: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/application',
          translation: 'configuration:toasts.appConfiguration',
        },
      },
      write: {
        [ApiEndpoint.DEFAULT]: {
          path: '/cdn/application',
          translation: 'configuration:toasts.appConfiguration',
        },
      },
    },
  };

  return configurations[key];
};
