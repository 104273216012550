import { WorkspaceMember } from '@core/types/api';
import { validateValueEqual } from '@utils/form';

export const getOwnerDetails = (permissions: WorkspaceMember[]): WorkspaceMember => {
  const ownerPermission = permissions?.find((permission) => validateValueEqual(permission.permission, 'owner'));

  return {
    id: ownerPermission?.id ?? 0,
    name: ownerPermission?.name ?? '',
    permission: ownerPermission?.permission ?? '',
    email: ownerPermission?.email ?? '',
    status: ownerPermission?.status ?? 0,
  };
};
