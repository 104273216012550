import { motion } from 'framer-motion';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components';
import { Translation } from '@enums';
import { useInitLoading, useLoginLoading, useUserDetails } from '@store';

import { getAllStorageData } from '@core/storage';
import { CreateNewPasswordForm } from '../subForm';

export const CreateNewPassword: FC = () => {
  const navigate = useNavigate();

  const { t: tTabs } = useTranslation(Translation.SignIn, { keyPrefix: 'tab' });

  const loading = useLoginLoading();
  const isInitLoading = useInitLoading();

  const user_details = useUserDetails();

  const { current_access_token, current_refresh_token } = getAllStorageData();

  const shouldNavigate = current_access_token && current_refresh_token && user_details && !loading && !isInitLoading;

  const memoizedNavigate = useCallback(() => {
    if (shouldNavigate) {
      navigate('/homepage');
    }
  }, [user_details]);

  useEffect(() => {
    memoizedNavigate();
  }, [memoizedNavigate]);

  return (
    <div className="flex flex-col gap-6">
      <motion.div
        className="text-start space-y-2.5"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.3 }}
      >
        <Text $level={2} $customizeColor className="leading-10 font-medium text-theme-text-main">
          {tTabs('setNewPassword')}
        </Text>
        <Text $level={5} $customizeColor className="text-gray-400/90">
          {`${tTabs('createANewPasswordForYourAccount')}`}
        </Text>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.3 }}
      >
        <CreateNewPasswordForm />
      </motion.div>
    </div>
  );
};
