import * as Yup from 'yup';

import { ValidationPatterns } from '@constants/global/validationPatterns';
import i18n from '@core/i18n';

type PatternType = keyof typeof ValidationPatterns;

type PatternRule = {
  regex: RegExp;
  type: string;
  format: string;
};

interface ValidationOptions {
  required?: boolean;
  select?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: PatternRule | PatternType;
  custom?: {
    validator: (value: any) => boolean | Promise<boolean>;
    message: string;
  };
}
interface ValidationRule {
  rule: (v: Yup.StringSchema, value: any) => Yup.StringSchema;
  value: any;
}

const createValidationRules = (rules: ValidationOptions): ValidationRule[] => {
  const validationRules: ValidationRule[] = [];

  if (rules.required) {
    if (rules.select) {
      validationRules.push({
        rule: (v: Yup.StringSchema) => v.required(i18n.t('validation:selectRequired')),
        value: true,
      });
    } else {
      validationRules.push({
        rule: (v: Yup.StringSchema) => v.required(i18n.t('validation:required')),
        value: true,
      });
    }
  }

  if (rules.minLength && rules.maxLength) {
    validationRules.push({
      rule: (v: Yup.StringSchema, lengths: { min: number; max: number }) =>
        v
          .min(lengths.min, i18n.t('validation:lengthRange', { min: lengths.min, max: lengths.max }))
          .max(lengths.max, i18n.t('validation:lengthRange', { min: lengths.min, max: lengths.max })),
      value: { min: rules.minLength, max: rules.maxLength },
    });
  } else if (rules.minLength) {
    validationRules.push({
      rule: (v: Yup.StringSchema, length: number) =>
        v.min(length, i18n.t('validation:minLength', { minLength: length })),
      value: rules.minLength,
    });
  } else if (rules.maxLength) {
    validationRules.push({
      rule: (v: Yup.StringSchema, length: number) =>
        v.max(length, i18n.t('validation:maxLength', { maxLength: length })),
      value: rules.maxLength,
    });
  }

  if (rules.pattern) {
    validationRules.push({
      rule: (v: Yup.StringSchema, pattern: PatternRule | PatternType) => {
        const patternRule = typeof pattern === 'string' ? ValidationPatterns[pattern] : pattern;
        return v.matches(
          patternRule.regex,
          i18n.t('validation:invalidFormat', {
            type: i18n.t(patternRule.type),
            description: i18n.t('validation:example', {
              format: i18n.t(patternRule.format),
            }),
          }),
        );
      },
      value: rules.pattern,
    });
  }

  if (rules.custom) {
    validationRules.push({
      rule: (v: Yup.StringSchema, { validator, message }: NonNullable<ValidationOptions['custom']>) =>
        v.test('custom', message, validator),
      value: rules.custom,
    });
  }

  return validationRules;
};

export const formValidation = (rules: ValidationOptions = {}) => {
  const validation = createValidationRules(rules).reduce(
    (validator, { rule, value }) => rule(validator, value),
    Yup.string(),
  );

  return validation;
};

export const createFormField = (name: string, options: ValidationOptions) => ({
  [name]: formValidation(options),
});
