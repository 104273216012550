import { Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, FormField, PredefinedCards, Skeleton } from '@components';
import { SCREEN_BREAKPOINTS, securityFormSchema } from '@constants';
import { Dictionaries, FormType, InputMod, InputType, StatusType, Translation } from '@enums';
import { useAppDispatch, useErrorFormInner, useMediaQuery } from '@hooks';
import {
  addSnack,
  resetSubmitFormState,
  submitFormRequest,
  useFormContent,
  useFormError,
  useFormLoadingStatus,
  useFormSaveStatus,
  useFormSubmissionStatus,
} from '@store';
import { getfilteredOptionList, getOptionListFromCatalog } from '@utils';

import { CacheFileExtensionModal } from '../Cache';
import { BlockRegionModal } from './subComponents/BlockRegionModal';

const initialSecurityValues = {
  soft_request_limit: 50,
  soft_request_limit_unit: 'qps',
  block_request_limit: 100,
  block_request_limit_unit: 'qps',
  block_action: 'javascript_verify',
  under_attack_mode: false,
  user_agent_black_list: [],
  ip_black_list: [],
  country_black_list: [],
};

export const SecurityForm = ({ onFormikInstance, formikRef }) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnSecurity;

  const data = useFormContent({ formType });
  const isDataLoading = useFormLoadingStatus({ formType });
  const isSubmitting = useFormSubmissionStatus({ formType });
  const submitError = useFormError({ formType });
  const saveSuccess = useFormSaveStatus(formType);

  const { t: tcButton } = useTranslation(Translation.Common, { keyPrefix: 'button' });
  const { t: tDescription } = useTranslation(Translation.Description, { keyPrefix: 'configuration.security' });
  const { t: tToasts } = useTranslation(Translation.Configuration, { keyPrefix: 'toasts.security' });
  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.security' });
  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.security' });

  const [openBlockUserAgentModal, setOpenBlockUserAgentModal] = useState<boolean>(false);
  const [openBlockUserIPModal, setOpenBlockUserIPModal] = useState<boolean>(false);
  const [openBlockRegionModal, setOpenBlockRegionModal] = useState<boolean>(false);
  const [showCurrentToast, setShowCurrentToast] = useState<boolean>(false);
  const [currentDataKey, setCurrentDataKey] = useState<string | null>(null);

  const isAboveSm = useMediaQuery(`(min-width: ${SCREEN_BREAKPOINTS.sm}px)`);

  const initialValues = {
    soft_request_limit: data?.soft_request_limit ?? initialSecurityValues.soft_request_limit,
    soft_request_limit_unit: data?.soft_request_limit_unit ?? initialSecurityValues.soft_request_limit_unit,
    block_request_limit: data?.block_request_limit ?? initialSecurityValues.block_request_limit,
    block_request_limit_unit: data?.block_request_limit_unit ?? initialSecurityValues.block_request_limit_unit,
    block_action: data?.block_action ?? initialSecurityValues.block_action,
    under_attack_mode: data?.under_attack_mode ?? initialSecurityValues.under_attack_mode,
    user_agent_black_list:
      data?.user_agent_black_list?.map((extension, index) => `${index !== 0 ? '\n' : ''}${extension}`) ??
      initialSecurityValues.user_agent_black_list,
    ip_black_list:
      data?.ip_black_list?.map((extension, index) => `${index !== 0 ? '\n' : ''}${extension}`) ??
      initialSecurityValues.ip_black_list,
    country_black_list: data?.country_black_list ?? initialSecurityValues.country_black_list,
    search: '',
  };

  const handleSubmit = (values: any) => {
    const { search, user_agent_black_list: user_agent, ip_black_list: ip, ...updatedValues } = values;

    const user_agent_black_list = user_agent?.map((value) => value.replace(/\n/g, '')) ?? [];
    const ip_black_list = ip?.map((value) => value.replace(/\n/g, '')) ?? [];

    dispatch(
      submitFormRequest({
        formType,
        formData: { application_id, user_agent_black_list, ip_black_list, ...updatedValues },
        globalOptions: {
          returnResult: true,
          content: true,
        },
      }),
    );
  };

  useEffect(() => {
    if (!submitError && !isSubmitting && saveSuccess) {
      if (showCurrentToast && currentDataKey) {
        dispatch(addSnack({ type: StatusType.Success, message: tToasts(`${currentDataKey}Configured`) }));
        setShowCurrentToast(false);
        setCurrentDataKey(null);
      }
    }
    return () => {
      dispatch(resetSubmitFormState({ formType }));
    };
  }, [application_id, currentDataKey, isSubmitting, saveSuccess, showCurrentToast, submitError]);

  const onOptionClick = (event, formik, name) => {
    if (!formik.errors[name] && formik.isValid) {
      formik.setFieldValue(name, event);
      const cleanedArray = event.map((value) => value.replace(/\n/g, ''));
      dispatch(
        submitFormRequest({
          formType,
          formData: { application_id, [name]: cleanedArray },
          globalOptions: { returnResult: true, content: true, toast: false },
        }),
      );
      setShowCurrentToast(true);
      setCurrentDataKey(name);
    }
  };

  const SecurityFormInner = () => {
    useErrorFormInner({ formType, isSubmitting });
    useEffect(() => {
      if (formikRef.current?.errors && Object.keys(formikRef.current.errors).length > 0) {
        formikRef.current.validateForm();
      }
    }, [tForm]);
    return null;
  };

  if (isDataLoading) {
    return (
      <div className="space-y-4">
        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} className="flex w-full rounded-2xl h-32" />
        ))}
      </div>
    );
  }

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={securityFormSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        if (onFormikInstance) onFormikInstance(formik);

        const handleModalResetAndClose = (modalSetter, openModal, fieldName) => {
          setCurrentDataKey(null);
          formik.setFieldValue(fieldName, initialValues[fieldName]);
          formik.setFieldError(fieldName, undefined);
          modalSetter(!openModal);
        };

        const handleBlockUserAgentModalClose = () => {
          handleModalResetAndClose(setOpenBlockUserAgentModal, openBlockUserAgentModal, 'user_agent_black_list');
        };

        const handleBlockUserIPModalClose = () => {
          handleModalResetAndClose(setOpenBlockUserIPModal, openBlockUserIPModal, 'ip_black_list');
        };

        const handleBlockRegionModalClose = () => {
          handleModalResetAndClose(setOpenBlockRegionModal, openBlockRegionModal, 'country_black_list');
        };

        return (
          <Fragment>
            <SecurityFormInner />
            <PredefinedCards
              title={tDescription('underAttackMode')}
              description={tDescription('underAttackMode_description')}
              besideTitle={!isAboveSm}
            >
              <FormField
                label={tForm('underAttackMode')}
                name="under_attack_mode"
                inputType={InputType.Switch}
                withoutLabel
                smallFont
                disabled={isSubmitting}
                placeholder={tForm('selectYourUnderAttackMode')}
                options={getOptionListFromCatalog(Dictionaries.UnderAttackMode)}
              />
            </PredefinedCards>

            <PredefinedCards
              title={tDescription('limitRequestRatePerIP')}
              description={tDescription('limitRequestRatePerIP_description')}
            >
              <FormField
                label={tForm('limitRequestRate')}
                name="soft_request_limit"
                type="number"
                min={1}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                adornmentWithoutM
                customizeColor
                textInputClassName="bg-white"
                fullWidth
                adornment={
                  <FormField
                    label={tForm('unit')}
                    name="soft_request_limit_unit"
                    inputType={InputType.Select}
                    withoutLabel
                    inputMod={InputMod.Filled}
                    smallFont
                    centerItem
                    customWidth="w-20"
                    withoutRing
                    className="flex border-l"
                    loading={isSubmitting}
                    placeholder={tForm('selectYourUnit')}
                    options={getfilteredOptionList({
                      dictionaries: Dictionaries.RequestRateUnit,
                      availableVariables: ['qps'],
                    })}
                  />
                }
                loading={isSubmitting}
                placeholder={tForm('limitRequestRateRange')}
              />
            </PredefinedCards>

            <PredefinedCards
              title={tDescription('rejectRequestPerIP')}
              description={tDescription('rejectRequestPerIP_description')}
            >
              <FormField
                label={tForm('rejectRequest')}
                name="block_request_limit"
                type="number"
                min={1}
                customizeColor
                adornmentWithoutM
                textInputClassName="bg-white"
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                adornment={
                  <FormField
                    label={tForm('unit')}
                    name="block_request_limit_unit"
                    inputType={InputType.Select}
                    withoutLabel
                    inputMod={InputMod.Filled}
                    smallFont
                    centerItem
                    customWidth="w-20"
                    withoutRing
                    className="flex border-l"
                    loading={isSubmitting}
                    placeholder={tForm('selectYourUnit')}
                    options={getfilteredOptionList({
                      dictionaries: Dictionaries.RequestRateUnit,
                      availableVariables: ['qps'],
                    })}
                  />
                }
                fullWidth
                loading={isSubmitting}
                placeholder={tForm('limitRequestRateRange')}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tDescription('rejectAction')}
              description={tDescription('rejectAction_description')}
            >
              <FormField
                label={tForm('rejectAction')}
                name="block_action"
                inputType={InputType.Select}
                inputMod={InputMod.Filled}
                withoutLabel
                smallFont
                fullWidth
                loading={isSubmitting}
                placeholder={tForm('selectYourBlockAction')}
                options={getOptionListFromCatalog(Dictionaries.DashboardBlockAction)}
              />
            </PredefinedCards>
            <PredefinedCards
              title={tDescription('blockUserAgent')}
              description={tDescription('blockUserAgent_description')}
            >
              <Button
                onClick={() => setOpenBlockUserAgentModal(true)}
                fullWidth
                loading={isSubmitting}
                className="flex"
              >
                {tButton('addUserAgentToBlockList')}
              </Button>
            </PredefinedCards>
            <PredefinedCards title={tDescription('blockUserIP')} description={tDescription('blockUserIP_description')}>
              <Button onClick={() => setOpenBlockUserIPModal(true)} fullWidth loading={isSubmitting} className="flex">
                {tButton('addIPToBlockList')}
              </Button>
            </PredefinedCards>
            <PredefinedCards title={tDescription('blockRegion')} description={tDescription('blockRegion_description')}>
              <Button
                onClick={() => setOpenBlockRegionModal(true)}
                fullWidth
                loading={isSubmitting}
                className="flex sm:max-w-52"
              >
                {tButton('addRegionToBlockList')}
              </Button>
            </PredefinedCards>
            {openBlockUserAgentModal && (
              <CacheFileExtensionModal
                open={openBlockUserAgentModal}
                title={tForm('configurationBlockUserAgent')}
                placeholder={`Mozilla\nAppleWebKit\nAxios\nSafari`}
                description={tDescription('configureBlockUserAgent')}
                formik={formik}
                onSubmit={onOptionClick}
                onClose={handleBlockUserAgentModalClose}
                name="user_agent_black_list"
                formType={formType}
              />
            )}
            {openBlockUserIPModal && (
              <CacheFileExtensionModal
                open={openBlockUserIPModal}
                title={tForm('configurationBlockUserIP')}
                placeholder={`8.8.8.8\n10.0.0.1\n192.168.1.1\n172.16.0.1`}
                description={tDescription('configureBlockUserIP')}
                formik={formik}
                onSubmit={onOptionClick}
                onClose={handleBlockUserIPModalClose}
                name="ip_black_list"
                formType={formType}
              />
            )}
            {openBlockRegionModal && (
              <BlockRegionModal
                open={openBlockRegionModal}
                label={{
                  title: tForm('configurationBlockRegion'),
                  description: tDescription('blockRegion_description'),
                  placeholder: tForm('searchByRegionName'),
                  confirmButton: tcButton('configure'),
                }}
                formik={formik}
                onSubmit={onOptionClick}
                onClose={handleBlockRegionModalClose}
                formType={formType}
                name="country_black_list"
              />
            )}
          </Fragment>
        );
      }}
    </Formik>
  );
};
