import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RecaptchaRef } from '@core/types/global/ref';
import type { AuthSliceState } from '@core/types/slices/authSlice';
import { RootState, useAppSelector } from '@hooks';

const authInitialState: AuthSliceState = {
  isLoading: false,
  isAuthenticated: false,
  recaptchaToken: null,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    login: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = true;
    },
    loginSuccess: (state) => {
      state.isAuthenticated = true;
      state.isLoading = false;
    },
    loginError: (state, { payload }: PayloadAction<Error>) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.error = payload;
    },
    logOut: (state) => {
      state.isLoading = false;
      state.isAuthenticated = authInitialState.isAuthenticated;
      state.recaptchaToken = null;
    },
    changeSystemAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    executeRecaptcha: (state, { payload }: PayloadAction<RecaptchaRef>) => {},
    executeRecaptchaSuccess: (state, { payload }: PayloadAction<string>) => {
      state.recaptchaToken = payload;
    },
    executeRecaptchaError: (state, { payload }: PayloadAction<Error>) => {
      state.recaptchaToken = null;
      state.error = payload;
    },
    clearLoginError: (state) => {
      state.error = null;
    },
    resetAuthState: (state) => {
      state.isAuthenticated = authInitialState.isAuthenticated;
      state.recaptchaToken = null;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginError,
  logOut,
  changeSystemAuthenticated,
  clearLoginError,
  executeRecaptcha,
  executeRecaptchaSuccess,
  executeRecaptchaError,
  resetAuthState,
} = authSlice.actions;

export const useLoginLoading = () => useAppSelector((state: RootState) => state.auth.isLoading);
export const useLoginError = () => useAppSelector((state: RootState) => state.auth.error);
export const useUserAuthenticated = () => useAppSelector((state: RootState) => state.auth.isAuthenticated);

const authReducer = authSlice.reducer;
export default authReducer;
