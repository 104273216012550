import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@components';
import { ROUTES } from '@constants';
import { Translation, VariantType } from '@enums';
import { useFormSubmissionStatus } from '@store';
import type { FormMetadata, LabelPayloadProps } from '@types';

interface LoginButtonGroupParams {
  label?: LabelPayloadProps;
  formParams: FormMetadata;
  hasNavigate?: boolean;
}
export const LoginButtonGroup: FC<LoginButtonGroupParams> = ({ label, formParams, hasNavigate = false }) => {
  const navigate = useNavigate();

  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });
  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'button' });

  const isFormSubmitting = useFormSubmissionStatus(formParams);

  const handleNavigateBackToLogin = () => {
    navigate(ROUTES.login);
  };

  return (
    <motion.div
      className="flex justify-end pt-5 flex-col gap-7"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.3 }}
    >
      <Button
        loading={isFormSubmitting}
        fullWidth
        rounded="rounded-lg"
        type="submit"
        withoutPadding
        className="px-2.5 py-3.5 justify-end text-theme-text-main bg-sky-200 bg-opacity-70"
      >
        {label?.confirmButton ?? tCommon('confirm')}
      </Button>
      {hasNavigate && (
        <Button
          disabled={isFormSubmitting}
          variant={VariantType.Transparent}
          customizeColor
          withoutPadding
          fullWidth
          rounded="rounded-lg"
          onClick={handleNavigateBackToLogin}
          icon={<ArrowLeft className="size-4" />}
          className="px-2.5 justify-end text-gray-400/85"
        >
          {label?.customButton ?? tButton('backToLogin')}
        </Button>
      )}
    </motion.div>
  );
};
