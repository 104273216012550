import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { workspaceOwnerFormSchema } from '@constants';
import { WorkspaceMember } from '@core/types/api';
import {
  ApiEndpoint,
  FormActionType,
  FormType,
  InputType,
  ServiceModalName,
  Translation,
  WorkspaceMemberEndpoint,
} from '@enums';
import { useAppDispatch } from '@hooks';
import { addServiceModal, submitFormRequest, updateFormProcedure } from '@store';
import type { ServiceModalPayload } from '@types';
import { convertOptionList } from '@utils';
import { getOwnerDetails } from '@utils/pages';
import { WsConfigCard } from '../components';

interface WsConfigOwnershipProps {
  workspaceMember: WorkspaceMember[];
  isOwner: boolean;
}

export const WsConfigOwnership: React.FC<WsConfigOwnershipProps> = ({ workspaceMember, isOwner = false }) => {
  const { id: application_id } = useParams();
  const dispatch = useAppDispatch();

  const formType = FormType.Workspace;

  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'button' });
  const { t: tModal } = useTranslation(Translation.Workspace, { keyPrefix: 'modals' });
  const { t: tDescription } = useTranslation(Translation.Workspace, { keyPrefix: 'descriptions' });
  const { t: tForms } = useTranslation(Translation.Workspace, { keyPrefix: 'forms' });
  const { t: tTabs } = useTranslation(Translation.Workspace, { keyPrefix: 'tabs' });
  const { t: tButtons } = useTranslation(Translation.Workspace, { keyPrefix: 'buttons' });

  const ownerShipDetails = useMemo(() => getOwnerDetails(workspaceMember), [workspaceMember]);
  const workspaceOwnerSchema = useMemo(() => workspaceOwnerFormSchema(), [tCommon]);

  const { options: ownerOptions } = useMemo(
    () =>
      convertOptionList({
        originalOptions: workspaceMember ?? [],
        defaultOptions: '',
        output: { value: 'email', label: 'name' },
      }),
    [workspaceMember, tForms],
  );

  const configItems = [
    {
      left: tForms('ownerName'),
      right: ownerShipDetails?.name,
    },
    {
      left: tForms('ownerEmail'),
      right: ownerShipDetails?.email,
    },
  ];

  const inputList = useMemo(
    () => [
      {
        name: 'email',
        defaultValue: '',
        inputType: InputType.Select,
        placeholder: tForms('selectNewWorkspaceOwner'),
        label: tForms('ownership'),
        options: ownerOptions,
      },
    ],
    [ownerOptions],
  );

  const initialValues = useMemo(
    () => ({
      email: ownerShipDetails?.email,
    }),
    [ownerShipDetails],
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        submitFormRequest({
          formType,
          formData: values,
          params: {
            content_id: application_id,
          },
          globalOptions: {
            returnResult: true,
            content: true,
            endPath: true,
            returnPath: ApiEndpoint.CONFIGURATION,
            endpoint: WorkspaceMemberEndpoint.TRANSFER,
          },
        }),
      );
    },
    [application_id, formType],
  );

  const handleCancel = () => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Idle }));
  };

  const modalPayload = useMemo(
    () => ({
      name: ServiceModalName.FormModal,
      payload: {
        label: {
          title: tModal('transferOwnership'),
          text: tDescription('transferControlOfThisWorksapceToAnotherUser'),
          confirmButton: tCommon('transfer'),
        },
        option: {
          modalForm: { initialValues, inputList, validationSchema: workspaceOwnerSchema },
          modalTable: { formType, endpoint: WorkspaceMemberEndpoint.TRANSFER },
          button: { loading: false },
          size: 'md',
        },
        callbackFunction: {
          submit: handleSubmit,
          cancel: handleCancel,
        },
      } as ServiceModalPayload,
    }),
    [inputList, tModal, tDescription, tCommon],
  );

  const handleModal = useCallback(() => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Transfer }));
    dispatch(addServiceModal(modalPayload));
  }, [modalPayload]);

  return (
    <WsConfigCard
      label={{ title: tTabs('workspaceOwner'), confirmButton: tButtons('transferOwnership') }}
      infoItems={configItems}
      callbackFunction={{
        ...(isOwner && { click: handleModal }),
      }}
    />
  );
};
