import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Cards, DraggableTable, Text } from '@components';
import { cdnErrorLogTable } from '@constants';
import { DashboardEndpoint, FormType, Translation, VariantType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  fetchDashboardGraph,
  updateFormPages,
  useFormLastUpdated,
  useFormList,
  useFormLoadingStatus,
  useFormPagination,
} from '@store';
import { formatUnixTimestamp } from '@utils';

export const ErrorLog = () => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();
  const formType = FormType.CdnDashboard;

  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [refreshTrigger, setRefreshTrigger] = useState<number>(1);

  const { t: tTabs } = useTranslation(Translation.Configuration, { keyPrefix: 'tabs.dashboard' });
  const { t: tTables } = useTranslation(Translation.Description, { keyPrefix: 'configuration.tables' });

  const errorLogTableColumns = useMemo(cdnErrorLogTable, [tTables]);

  const lastUpdated = useFormLastUpdated(formType);
  const loggedData = useFormList({ formType, endpoint: DashboardEndpoint.ERROR_LOG });
  const isDataLoading = useFormLoadingStatus({ formType, endpoint: DashboardEndpoint.ERROR_LOG });
  const pagination = useFormPagination({ formType, endpoint: DashboardEndpoint.ERROR_LOG });

  const formattedLastUpdated = formatUnixTimestamp(lastUpdated);

  useEffect(() => {
    if (!application_id) return;

    if (refreshTrigger !== 0) {
      dispatch(
        fetchDashboardGraph({
          formType,
          params: { application_id },
          globalOptions: {
            pagination: true,
            endpoint: [DashboardEndpoint.ERROR_LOG],
          },
        }),
      );
      setIsReloading(false);
    }
  }, [application_id, refreshTrigger]);

  const handlePageChange = (page: number) => {
    dispatch(
      updateFormPages({
        formType,
        page,
        params: { application_id },
        globalOptions: {
          endpoint: DashboardEndpoint.ERROR_LOG,
        },
      }),
    );
  };

  const handleReloadLog = () => {
    setIsReloading(true);
    setRefreshTrigger((prev) => prev + 1);
  };

  return (
    <Cards rounded="rounded-2xl px-8 py-7 space-y-6 max-w-full overflow-hidden" withoutPadding>
      <div className="flex justify-between">
        <article className="font-medium text-theme-primary-main">{tTabs('errorLogs')}</article>
        <div className="flex gap-5 items-center">
          <Text className="" color="text-gray-500" $level={6}>
            {tTabs('lastUpdated', { date: formattedLastUpdated })}
          </Text>
          <Button
            onClick={handleReloadLog}
            variant={VariantType.Icon}
            disabled={isReloading}
            icon={<ArrowPathIcon className={`w-4 h-4 text-blue-500 ${isReloading ? 'animate-spin' : ''}`} />}
          />
        </div>
      </div>
      <DraggableTable
        rows={loggedData}
        columns={errorLogTableColumns}
        isDataLoading={isDataLoading || isReloading}
        pagination={pagination}
        onChangePage={handlePageChange}
      />
    </Cards>
  );
};
