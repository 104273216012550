import clsx from 'clsx';
import i18n from 'i18next';
import { LucideShieldAlert, LucideShieldCheck } from 'lucide-react';

import { Button, Column, Text } from '@components';
import { CellType, FormActionType } from '@enums';
import { getColumnSizes } from '@utils';

export const getCdnDomainListColumns = ({ cname, isDataSubmitting, actionHandlers }): Column[] => [
  { label: i18n.t('configuration:tables.id'), value: 'id', ...getColumnSizes(50) },
  { label: i18n.t('configuration:tables.name'), value: 'name', ...getColumnSizes(100) },
  {
    label: i18n.t('configuration:tables.domain'),
    value: 'domain',
    ...getColumnSizes(140),
    className: '',
    cellType: CellType.Custom,
    format: (value, fullData) => {
      const isVerified = fullData?.cname_verified;

      return (
        <div className="flex gap-2 items-center">
          <Text $level={4} color="text-black" className="font-normal truncate leading-6 select-text">
            {value}
          </Text>
          <Button
            iconWithoutM
            customizeColor
            withoutPadding
            disabled={isDataSubmitting || isVerified || !cname}
            onClick={() => actionHandlers[FormActionType.Verification]?.handler(fullData)}
            icon={
              isVerified ? (
                <LucideShieldCheck className="size-4 text-green-500" />
              ) : (
                <LucideShieldAlert
                  className={clsx(
                    {
                      'text-gray-300': !cname || isDataSubmitting,
                      'text-red-500': cname && !isVerified && !isDataSubmitting,
                    },
                    'size-4',
                  )}
                />
              )
            }
          />
        </div>
      );
    },
  },
  {
    label: i18n.t('configuration:tables.origin'),
    value: 'origin',
    ...getColumnSizes(70),
  },
  { label: i18n.t('configuration:tables.action'), value: 'actions', cellType: CellType.Action, ...getColumnSizes(90) },
];
