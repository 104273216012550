import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Text } from '@components';
import { ROUTES, SignInSchema, USER_LOGIN_FORM_CONFIG } from '@constants';
import { InputMod, Translation, VariantType } from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { login, useLoginError, useLoginLoading } from '@store';
import type { UserLoginFormValues } from '@types';
import { LoginFormFieldWrapper } from '../subComponents';

export const SignInForm = ({ recaptchaRef }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });

  const isLoginLoading = useLoginLoading();
  const loginError = useLoginError();

  const handleSubmit = (values: UserLoginFormValues) => {
    dispatch(login({ values, recaptchaRef }));
  };

  const { fields, initialValues } = USER_LOGIN_FORM_CONFIG;

  const SignInFormInner = () => {
    useErrorFormInner({ submitError: loginError, isSubmitting: isLoginLoading });
    return null;
  };

  const handleForgotPassword = () => {
    navigate(ROUTES.resetPassword);
  };

  const handleSignUp = () => {
    navigate(ROUTES.registration);
  };

  return (
    <Formik
      initialValues={initialValues as UserLoginFormValues}
      validationSchema={SignInSchema}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form className="space-y-4 pt-2">
            <SignInFormInner />
            <div className="space-y-6">
              {fields.map((field) => (
                <LoginFormFieldWrapper
                  key={field.name}
                  field={{
                    ...field,
                    textInputClassName:
                      'bg-theme-neutral-main text-white rounded-lg px-4 py-3 focus:ring-2 focus:ring-theme-primary transition-all duration-300',
                    fullWidth: true,
                    customizeColor: true,
                    withoutRing: true,
                    inputMod: InputMod.Filled,
                    withoutLabel: true,
                  }}
                  translation={tSignIn}
                />
              ))}
            </div>
            <motion.div
              className="grid grid-cols-1 gap-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              <Button
                disabled={isLoginLoading}
                variant={VariantType.Transparent}
                onClick={handleForgotPassword}
                withoutPadding
                rounded="rounded-lg"
                className="bg-transparent pt-0.5 text-red-500/90 hover:underline justify-self-end"
              >
                {`${tButton('forgotPassword')} ?`}
              </Button>
              <Button
                type="submit"
                fullWidth
                loading={isLoginLoading}
                rounded="rounded-lg"
                withoutPadding
                className="px-2.5 py-3.5 justify-end text-theme-text-main bg-sky-200 bg-opacity-70"
                data-testid="signIn-submit-button"
              >
                {tButton('signIn')}
              </Button>
              <Text $level={6} color="text-gray-400 text-center">
                {`${tButton('dontHaveAnAccount')}? `}
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSignUp();
                    }
                  }}
                  className="text-blue-400 underline hover:cursor-pointer"
                  onClick={handleSignUp}
                >
                  {tButton('signUp')}
                </span>
              </Text>
            </motion.div>
          </Form>
        );
      }}
    </Formik>
  );
};
