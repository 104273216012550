export enum ApiEndpoint {
  VERIFY = 'verify',
  SORTING = 'sorting',
  DEFAULT = 'default',
  LOG = 'log',
  WHITELIST = 'whitelist',
  CUSTOM = 'custom',
  DEPLOY = 'deploy',
  CLEAR = 'clear',
  CONFIGURATION = 'configuration',
}

export enum DashboardEndpoint {
  QPS = 'qps',
  REQUEST_PER_IP = 'request_per_ip',
  TOP_TEN_URL = 'top_ten_url',
  ATTACK_COUNT = 'attack_count',
  CACHE = 'cache',
  TOTAL_TRAFFIC = 'total_traffic',
  TRAFFIC_LOG = 'traffic_log',
  ERROR_LOG = 'error_log',
  ATTACK_LOG = 'attack_log',
}

export enum WorkspaceMemberEndpoint {
  JOIN = 'join',
  MEMBER = 'member',
  TRANSFER = 'transfer',
  RESEND_INVITATION = 'resendInvitation',
  ACCEPT = 'accept',
}

export enum UserEndpoint {
  REGISTER = ' register',
  VERIFY_EMAIL = 'verify_email',
  FORGOT_PASSWORD = 'forgot_password',
  NEW_PASSWORD = 'new_password',
}
