import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Text } from '@components';
import { FORGOT_PASSWORD_FORM_CONFIG, ForgotPasswordSchema } from '@constants';
import { FormType, StatusType, Translation, UserEndpoint } from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { addSnack, useFormError, useFormSubmissionStatus } from '@store';
import type { ForgotPasswordFormValues } from '@types';
import { LoginButtonGroup, LoginFormFieldWrapper } from '../subComponents';

export const ForgotPasswordForm = () => {
  const dispatch = useAppDispatch();

  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });
  const { t: tToasts } = useTranslation(Translation.SignIn, { keyPrefix: 'toast' });

  const formType = FormType.User;
  const forgotPasswordFormMetaData = { formType, endpoint: UserEndpoint.FORGOT_PASSWORD };

  const isFormSubmitting = useFormSubmissionStatus(forgotPasswordFormMetaData);
  const isFormError = useFormError(forgotPasswordFormMetaData);

  const handleSubmit = (values: ForgotPasswordFormValues) => {
    console.log(values);
    dispatch(addSnack({ type: StatusType.Success, message: tToasts('passwordResetLinkSentToYourEmail') }));
  };

  const LoginFormInner = () => {
    useErrorFormInner({ submitError: isFormError, isSubmitting: isFormSubmitting });
    return null;
  };
  const { fields, initialValues } = FORGOT_PASSWORD_FORM_CONFIG;

  return (
    <Formik
      initialValues={initialValues as ForgotPasswordFormValues}
      validationSchema={ForgotPasswordSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="space-y-4 pt-2">
          <LoginFormInner />

          {fields.map((field) => (
            <LoginFormFieldWrapper
              key={field.name}
              field={{
                ...field,
                label: (
                  <Text $level={5} $customizeColor className="text-slate-400">
                    {tSignIn(String(field.label))}
                  </Text>
                ),
                fullWidth: true,
                customizeColor: true,
                withoutRing: true,
                smallFont: true,
                textInputClassName:
                  'bg-theme-neutral-main text-white rounded-lg focus:ring-2 focus:ring-theme-primary transition-all duration-300',
              }}
              translation={tSignIn}
            />
          ))}
          <LoginButtonGroup
            label={{ confirmButton: tButton('resetPassword') }}
            formParams={forgotPasswordFormMetaData}
            hasNavigate
          />
        </Form>
      )}
    </Formik>
  );
};
