import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { FormType } from '@enums';
import { useFormError, useFormSubmissionStatus } from '@store';

// Define proper error types
type ErrorValue = string | string[] | Record<string, unknown>;

interface UseErrorFormInnerProps {
  formType?: FormType;
  submitError?: ErrorValue;
  isSubmitting?: boolean;
}

const processError = (error: unknown): string => {
  if (!error) {
    return '';
  }

  if (typeof error === 'string') {
    return error;
  }

  if (Array.isArray(error)) {
    return error
      .map((item) => {
        if (typeof item === 'object' && item !== null) {
          return Object.values(item).join('\n ');
        }
        return String(item);
      })
      .join('\n ');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.values(error).join('\n ');
  }

  return String(error);
};

const getFullFieldPath = (parentField: string, field: string): string => {
  if (!parentField) {
    return field;
  }

  const isNumericKey = !Number.isNaN(Number(field));
  return isNumericKey ? parentField : `${parentField}.${field}`;
};

export const useErrorFormInner = ({
  formType = FormType.Empty,
  submitError,
  isSubmitting = false,
}: UseErrorFormInnerProps) => {
  const { setFieldError, setFieldTouched } = useFormikContext();
  const formError = useFormError({ formType });
  const formSubmitting = useFormSubmissionStatus({ formType });

  const isFormError = formError ?? submitError;
  const isFormSubmitting = formSubmitting ?? isSubmitting;

  useEffect(() => {
    if (isFormSubmitting || !isFormError) {
      return;
    }

    const setError = (field: string, error: unknown) => {
      const errorMessage = processError(error);
      setFieldError(field, errorMessage);
      setFieldTouched(field, true, false);
    };

    const traverseErrors = (errors: Record<string, unknown>, parentField = '') => {
      if (!errors) {
        return;
      }

      Object.entries(errors).forEach(([field, error]) => {
        const fullField = getFullFieldPath(parentField, field);
        setError(fullField, error);
      });
    };

    if (typeof isFormError === 'string') {
      setError('submitError', isFormError);
    } else {
      traverseErrors(isFormError as Record<string, unknown>);
    }
  }, [isFormSubmitting, isFormError, setFieldError, setFieldTouched]);
};
