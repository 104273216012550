import { motion } from 'framer-motion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@components';
import { Translation } from '@enums';

import { ForgotPasswordForm } from '../subForm';

export const ForgotPassword: FC = () => {
  const { t: tTabs } = useTranslation(Translation.SignIn, { keyPrefix: 'tab' });

  return (
    <div className="flex flex-col gap-6">
      <motion.div
        className="text-start space-y-2.5"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.3 }}
      >
        <Text $level={2} $customizeColor className="leading-10 font-medium text-theme-text-main">
          {tTabs('forgotPassword')}
        </Text>
        <Text $level={5} $customizeColor className="text-gray-400/90 leading-relaxed">
          {`${tTabs('enterYourRegisteredEmailBelowToReceivePasswordResetInstruction')}?`}
        </Text>
      </motion.div>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.3 }}
      >
        <ForgotPasswordForm />
      </motion.div>
    </div>
  );
};
