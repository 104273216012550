import classNames from 'classnames';
import { FieldArray, useField, useFormikContext } from 'formik';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ConditionTableForm, FormField, Text } from '@components';
import { routeRulesConditionField, SCREEN_BREAKPOINTS } from '@constants';
import { columnSpace, ConditionVariableType, Dictionaries, InputMod, InputType, Translation } from '@enums';
import { useMediaQuery } from '@hooks';
import { getOptionListFromCatalog, validateValueEqual } from '@utils';

interface ConditionTableInputProps {
  name: string;
  columnPercentage?: columnSpace;
  format?: (key: string) => string;
  emptyData: { main: object };
  className: string;
}

export const ConditionTableInput = ({
  name,
  columnPercentage = columnSpace.ThreeOverFive,
  format,
  emptyData,
  className = '',
}: PropsWithChildren<ConditionTableInputProps>) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<any>();

  const { t: tForm } = useTranslation(Translation.Form, { keyPrefix: 'common' });
  const { t: tTables } = useTranslation(Translation.Common, { keyPrefix: 'tables' });

  const isTwoOverFive = validateValueEqual(columnPercentage, columnSpace.TwoOverFive);
  const isThreeOverFive = validateValueEqual(columnPercentage, columnSpace.ThreeOverFive);
  const isHalf = validateValueEqual(columnPercentage, columnSpace.Half);

  const isAboveSm = useMediaQuery(`(min-width: ${SCREEN_BREAKPOINTS.sm}px)`);

  const dynamicLabelClasses = classNames('flex flex-col', {
    'w-2/5': isTwoOverFive && isAboveSm,
    'w-3/5': isThreeOverFive && isAboveSm,
    'w-2/4': isHalf && isAboveSm,
    'w-full': !isAboveSm,
    [className]: className,
  });

  const dynamicTableClasses = classNames('flex flex-col gap-3', {
    'w-3/5': isTwoOverFive && isAboveSm,
    'w-2/5': isThreeOverFive && isAboveSm,
    'w-2/4': isHalf && isAboveSm,
    'w-full': !isAboveSm,
    [className]: className,
  });

  const variableOptions = useMemo(() => {
    const availableVariable = [
      ConditionVariableType.URI,
      ConditionVariableType.HostName,
      ConditionVariableType.Scheme,
      ConditionVariableType.ClientAddress,
      ConditionVariableType.ClientCountry,
      ConditionVariableType.UserAgent,
    ] as string[];

    return getOptionListFromCatalog(Dictionaries.Variable)?.filter((option) =>
      availableVariable.includes(option.value),
    );
  }, []);

  if (!name) return;
  return (
    <FieldArray name={name}>
      {({ push, remove: removeRow }) => (
        <div className="">
          {field?.value?.map((row, rowIndex) => {
            const handleFieldChange = (newFieldValue: any) => {
              setFieldValue(`${name}.[${rowIndex}].field`, newFieldValue);

              const conditionFields = routeRulesConditionField(newFieldValue) ?? [];
              conditionFields.forEach((fieldData) => {
                const fieldName = `${name}.[${rowIndex}].${fieldData.name}`;
                if (fieldData.name === 'values') {
                  setFieldValue(fieldName, [{ value: fieldData.emptyValue || '' }], false);
                } else {
                  setFieldValue(fieldName, fieldData.value, false);
                }
              });
            };
            return (
              <div key={rowIndex} className="flex flex-col sm:flex-row gap-3">
                <div className={dynamicLabelClasses}>
                  <Text $level={5} color="text-gray-700" className="text-left font-normal leading-6">
                    {`${tTables('condition')} ${rowIndex + 1}`}
                  </Text>
                </div>
                <div className={dynamicTableClasses}>
                  <FormField
                    fullWidth
                    smallFont
                    withoutLabel
                    name={`${name}.[${rowIndex}].field`}
                    label={tTables('conditionType')}
                    inputMod={InputMod.Filled}
                    inputType={InputType.Select}
                    options={variableOptions}
                    onChange={(e) => handleFieldChange(e.target.value)}
                  />
                  <ConditionTableForm
                    format={format}
                    name={name}
                    row={row}
                    rowId={rowIndex}
                    removeRow={removeRow}
                    emptyData={emptyData}
                  />
                  {!validateValueEqual(field.value?.length, 0) && (
                    <>
                      <div className="w-full inset-0 flex items-center relative">
                        <div className="absolute inset-0 flex justify-end">
                          <div className={`border-t border-dashed border-gray-300 w-full mb-1`}></div>
                        </div>
                      </div>

                      <div className="flex w-full pt-2">
                        {validateValueEqual(rowIndex, field.value?.length - 1) && (
                          <Button
                            className={` bg-theme-primary-main text-white w-full`}
                            onClick={() => push(emptyData.main)}
                          >
                            <Text $level={5} color="text-white" className="font-normal">
                              {tForm('addMoreCondition')}
                            </Text>
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}

          {validateValueEqual(field.value?.length, 0) && (
            <div className="flex flex-col sm:flex-row gap-3">
              <div className={dynamicLabelClasses}>
                <Text $level={5} color="text-gray-700" className={dynamicLabelClasses}>
                  {tTables('condition')}
                </Text>
              </div>
              <Button
                className={`px-3 bg-theme-primary-main text-white ${dynamicTableClasses}`}
                onClick={() => push(emptyData.main)}
              >
                <span className="text-us font-normal">{tForm('addMoreCondition')}</span>
              </Button>
            </div>
          )}
        </div>
      )}
    </FieldArray>
  );
};
