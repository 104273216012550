import * as Yup from 'yup';

import { ValidationType } from '@enums';
import { createFormField } from '@utils/system/getValidationMessages';

export const SignInSchema = Yup.object().shape({
  ...createFormField('username', {
    required: true,
    pattern: ValidationType.EMAIL,
  }),
  ...createFormField('password', {
    required: true,
    pattern: ValidationType.PASSWORD,
  }),
});
