import i18n from 'i18next';
import * as Yup from 'yup';

import { ValidationType } from '@enums';
import { createFormField } from '@utils/system/getValidationMessages';

export const workspaceFormSchema = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .email(() => i18n.t('validation:checkTheEmailFormat'))
        .required(() => i18n.t('validation:filledEmailRequired')),
    }),
  ),
});

export const workspaceOverviewFormSchema = () =>
  Yup.object().shape({
    ...createFormField('name', {
      required: true,
      pattern: ValidationType.LATIN_ALL_SPECIAL,
      minLength: 3,
      maxLength: 55,
    }),
  });

export const workspaceOwnerFormSchema = () =>
  Yup.object().shape({
    ...createFormField('email', {
      required: true,
      select: true,
      pattern: ValidationType.EMAIL,
    }),
  });

export const workspaceMemberFormSchema = () =>
  Yup.object().shape({
    ...createFormField('email', {
      required: true,
      pattern: ValidationType.EMAIL,
    }),
  });
