export enum ValidationType {
  LATIN_LETTERS_NUMBERS_CHARACTERS = 'LATIN_LETTERS_NUMBERS_CHARACTERS',
  LATIN_LETTERS_NUMBERS_NO_SPACES = 'LATIN_LETTERS_NUMBERS_NO_SPACES',
  LATIN_LETTERS_BASIC_SPECIAL = 'LATIN_LETTERS_BASIC_SPECIAL',
  LATIN_WITH_COMMA = 'LATIN_WITH_COMMA',
  LATIN_ALL_SPECIAL = 'LATIN_ALL_SPECIAL',
  LATIN_WITH_QUOTES = 'LATIN_WITH_QUOTES',
  LATIN_SPECIAL = 'LATIN_SPECIAL',
  LATIN_SPECIAL_EXTENDED = 'LATIN_SPECIAL_EXTENDED',
  LATIN_NUMERALS = 'LATIN_NUMERALS',
  NUMBERS_ONLY = 'NUMBERS_ONLY',
  LATIN_LETTERS_ONLY = 'LATIN_LETTERS_ONLY',
  POSITIVE_NEGATIVE_NUMBERS = 'POSITIVE_NEGATIVE_NUMBERS',
  FLOAT_NUMBERS = 'FLOAT_NUMBERS',
  NUMBERS_DECIMALS = 'NUMBERS_DECIMALS',
  NUMBERS_TWO_DECIMALS = 'NUMBERS_TWO_DECIMALS',
  NUMBERS_SPECIAL = 'NUMBERS_SPECIAL',
  EMAIL = 'EMAIL',
  IP_ADDRESS = 'IP_ADDRESS',
  DOMAIN = 'DOMAIN',
  URL = 'URL',
  PHONE = 'PHONE',
  DATE = 'DATE',
  TIME = 'TIME',
  PASSWORD = 'PASSWORD',
}
