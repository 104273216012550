import { useMemo } from 'react';

import { SCREEN_BREAKPOINTS } from '@constants';
import { useMediaQuery } from '@hooks/system';

const viewportBreakpoints = {
  mobile: `(max-width: ${SCREEN_BREAKPOINTS.sm}px)`,
  tablet: `(max-width: ${SCREEN_BREAKPOINTS.lg}px)`,
  desktop: `(max-width: ${SCREEN_BREAKPOINTS['2xl']}px)`,
} as const;

export const useViewportQueries = () => {
  const isMobile = useMediaQuery(viewportBreakpoints.mobile);
  const isTablet = useMediaQuery(viewportBreakpoints.tablet);
  const isDesktop = useMediaQuery(viewportBreakpoints.desktop);

  return useMemo(() => ({ isMobile, isTablet, isDesktop }), [isMobile, isTablet, isDesktop]);
};
