import { ValidationType } from '@enums/globals/validation';
import { ValidationRegExp } from './validationRegExp';

interface PatternRule {
  regex: RegExp;
  type: string;
  format: string;
}
export const ValidationPatterns: Record<ValidationType, PatternRule> = {
  // Latin letters and numbers combinations
  [ValidationType.LATIN_LETTERS_NUMBERS_CHARACTERS]: {
    regex: ValidationRegExp.latinLettersNumbersCharactersReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinLettersNumbersCharacters',
  },
  [ValidationType.LATIN_LETTERS_NUMBERS_NO_SPACES]: {
    regex: ValidationRegExp.latinLettersNumbersCharactersWithoutSpacesReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinLettersNumbersNoSpaces',
  },
  [ValidationType.LATIN_LETTERS_BASIC_SPECIAL]: {
    regex: ValidationRegExp.latinLettersNumbersSpecialCharactersReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinLettersBasicSpecial',
  },
  [ValidationType.LATIN_WITH_COMMA]: {
    regex: ValidationRegExp.latinLettersNumbersSpecialCharactersCommaReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinWithComma',
  },
  [ValidationType.LATIN_ALL_SPECIAL]: {
    type: 'validation:types.format',
    regex: ValidationRegExp.latinLettersNumbersAllSpecialCharactersReg,
    format: 'validation:formats.latinAllSpecial',
  },
  [ValidationType.LATIN_WITH_QUOTES]: {
    regex: ValidationRegExp.latinLettersNumbersSpecialSymbolsReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinWithQuotes',
  },
  // Latin letters with special characters
  [ValidationType.LATIN_SPECIAL]: {
    regex: ValidationRegExp.latinLettersSpecialCharactersReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinSpecial',
  },
  [ValidationType.LATIN_SPECIAL_EXTENDED]: {
    regex: ValidationRegExp.latinLettersSpecialSymbolsReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinSpecialExtended',
  },
  // Basic patterns
  [ValidationType.LATIN_NUMERALS]: {
    regex: ValidationRegExp.latinLettersNumeralsReg,
    type: 'validation:types.format',
    format: 'validation:formats.latinNumerals',
  },
  [ValidationType.NUMBERS_ONLY]: {
    regex: ValidationRegExp.numbersReg,
    type: 'validation:types.format',
    format: 'validation:formats.numbersOnly',
  },
  [ValidationType.LATIN_LETTERS_ONLY]: {
    regex: ValidationRegExp.latinLetters,
    type: 'validation:types.format',
    format: 'validation:formats.latinLettersOnly',
  },
  // Number formats
  [ValidationType.POSITIVE_NEGATIVE_NUMBERS]: {
    regex: ValidationRegExp.positiveNegativeNumbersReg,
    type: 'validation:types.format',
    format: 'validation:formats.positiveNegativeNumbers',
  },
  [ValidationType.FLOAT_NUMBERS]: {
    regex: ValidationRegExp.positiveNegativeNumbersFloatReg,
    type: 'validation:types.format',
    format: 'validation:formats.floatNumbers',
  },
  [ValidationType.NUMBERS_DECIMALS]: {
    regex: ValidationRegExp.numbersDecimalsReg,
    type: 'validation:types.format',
    format: 'validation:formats.numbersDecimals',
  },
  [ValidationType.NUMBERS_TWO_DECIMALS]: {
    regex: ValidationRegExp.numbersRequiredDecimalsReg,
    type: 'validation:types.format',
    format: 'validation:formats.numbersTwoDecimals',
  },
  [ValidationType.NUMBERS_SPECIAL]: {
    regex: ValidationRegExp.numbersSpecialCharactersReg,
    type: 'validation:types.format',
    format: 'validation:formats.numbersSpecial',
  },
  // Common validations
  [ValidationType.EMAIL]: {
    regex: ValidationRegExp.email,
    type: 'validation:types.emailAddress',
    format: 'validation:formats.email',
  },
  [ValidationType.IP_ADDRESS]: {
    regex: ValidationRegExp.ipAddress,
    type: 'validation:types.ipAddress',
    format: 'validation:formats.ipAddress',
  },
  [ValidationType.DOMAIN]: {
    regex: ValidationRegExp.domain,
    type: 'validation:types.domainName',
    format: 'validation:formats.domain',
  },
  [ValidationType.URL]: {
    regex: ValidationRegExp.url,
    type: 'validation:types.url',
    format: 'validation:formats.url',
  },
  [ValidationType.PHONE]: {
    regex: ValidationRegExp.phone,
    type: 'validation:types.phoneNumber',
    format: 'validation:formats.phone',
  },
  [ValidationType.DATE]: {
    regex: ValidationRegExp.date,
    type: 'validation:types.date',
    format: 'validation:formats.date',
  },
  [ValidationType.TIME]: {
    regex: ValidationRegExp.time,
    type: 'validation:types.time',
    format: 'validation:formats.time',
  },
  [ValidationType.PASSWORD]: {
    regex: ValidationRegExp.password,
    type: 'validation:types.password',
    format: 'validation:formats.password',
  },
} as const;
