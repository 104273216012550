import { DialogPanel } from '@headlessui/react';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DialogModal, ModalActions, ModalBody, ModalDescription, ModalTitle, Text } from '@components';
import { ApiEndpoint, FormType, Translation, VariantType } from '@enums';
import { useErrorFormInner, useFormSubmission } from '@hooks';
import { useFormSubmissionStatus } from '@store';
import { EndpointType } from '@types';

type FormProps = {
  title: ReactNode;
  open: boolean;
  onClose: () => void;
  confirmButton?: any;
  cancelButton?: any;
  zIndex?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  className?: string;
  description?: string | ReactNode;
  withoutPadding?: boolean;
  dataTestId?: string;
  submitError?: any;
  loading?: boolean;
  formType?: FormType;
  endpoint?: EndpointType;
  additionalReturnFunction?: () => void;
};

export const FormModal = ({
  submitError,
  withoutPadding = false,
  open = false,
  title,
  description,
  confirmButton,
  cancelButton,
  onClose,
  loading = false,
  zIndex,
  size = 'md',
  formType = FormType.Empty,
  endpoint = ApiEndpoint.DEFAULT,
  children,
  className = '',
  additionalReturnFunction,
}: PropsWithChildren<FormProps>) => {
  const { t: tButton } = useTranslation(Translation.Common, { keyPrefix: 'button' });
  const isSubmitting = useFormSubmissionStatus({ formType, endpoint });

  const modalClass = useMemo(
    () =>
      classNames('w-full relative flex flex-col transform transition-all text-left', {
        [className]: className,
      }),
    [className],
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleModalClose = useCallback(() => {
    !isSubmitting && onClose();
  }, [onClose]);

  useErrorFormInner({ formType, isSubmitting, submitError });
  useFormSubmission({
    formType,
    endpoint,
    callbackFunction: {
      close: onClose,
      additional: additionalReturnFunction,
    },
  });

  const renderTitle = useMemo(
    () => (typeof title !== 'string' ? title : <ModalTitle className="font-medium">{title}</ModalTitle>),
    [title],
  );

  const renderDescription = useMemo(
    () =>
      typeof description !== 'string' ? (
        description
      ) : (
        <ModalDescription className="text-gray-500 w-full">{description}</ModalDescription>
      ),
    [description],
  );

  const defaultCancelButton = useMemo(
    () => (
      <Button
        disabled={isSubmitting}
        variant={VariantType.Outlined}
        fullWidth
        onClick={handleCancel}
        className="items-center ring-theme-primary-main"
      >
        <Text $level={5} color="text-theme-priamry-main" className="ellipsis-text">
          {tButton('cancel')}
        </Text>
      </Button>
    ),
    [isSubmitting, handleCancel, tButton],
  );

  return (
    <DialogModal open={open} onClose={handleModalClose} size={size} withoutPadding={withoutPadding} style={{ zIndex }}>
      <DialogPanel className={modalClass}>
        {renderTitle}
        {renderDescription}
        <ModalBody className="max-w-full mt-8 sm:mt-11">
          {children}
          {!!confirmButton && (
            <ModalActions>
              {cancelButton ? (
                <Button
                  {...cancelButton}
                  variant={VariantType.Outlined}
                  fullWidth
                  disabled={isSubmitting}
                  data-testid="confirmModalCancelButton"
                />
              ) : (
                defaultCancelButton
              )}
              <Button
                type={confirmButton?.onClick ? 'button' : 'submit'}
                onClick={confirmButton?.onClick || undefined}
                fullWidth
                loading={isSubmitting || loading}
                {...confirmButton}
              />
            </ModalActions>
          )}
        </ModalBody>
      </DialogPanel>
    </DialogModal>
  );
};
