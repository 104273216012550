import { ShieldPlusIcon } from 'lucide-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getWsConfigMemberColumns, workspaceMemberFormSchema } from '@constants';
import { FormListLayout } from '@containers';
import {
  ApiEndpoint,
  FormActionType,
  FormType,
  InputType,
  ServiceModalName,
  StatusType,
  Translation,
  WorkspaceMemberEndpoint,
} from '@enums';
import { useAppDispatch, useFormHandlers } from '@hooks';
import { addServiceModal, deleteFormContent, removeServiceModal, submitFormRequest, updateFormProcedure } from '@store';
import { ServiceModalPayload, WorkspaceMember } from '@types';

interface WsConfigOwnershipProps {
  workspaceMember: WorkspaceMember[];
  isOwner: boolean;
}

export const WsConfigMember: FC<WsConfigOwnershipProps> = ({ workspaceMember, isOwner = false }) => {
  const { id: application_id } = useParams();
  const dispatch = useAppDispatch();

  const formType = FormType.Workspace;

  const { t: tCommon } = useTranslation(Translation.Common);
  const { t: tModal } = useTranslation(Translation.Workspace, { keyPrefix: 'modals' });
  const { t: tDescription } = useTranslation(Translation.Workspace, { keyPrefix: 'descriptions' });
  const { t: tTabs } = useTranslation(Translation.Workspace, { keyPrefix: 'tabs' });
  const { t: tForms } = useTranslation(Translation.Workspace, { keyPrefix: 'forms' });
  const { t: tButton } = useTranslation(Translation.Workspace, { keyPrefix: 'buttons' });

  const workspaceMemberSchema = useMemo(() => workspaceMemberFormSchema(), [tCommon]);

  const inputList = useMemo(
    () => [
      {
        name: 'email',
        defaultValue: '',
        inputType: InputType.Text,
        placeholder: tForms('enterTheEmailYouWantToInvite'),
        label: tForms('members'),
      },
    ],
    [workspaceMember, tCommon],
  );

  const initialValues = useMemo(
    () => ({
      email: '',
    }),
    [workspaceMember, tCommon],
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        submitFormRequest({
          formType,
          formData: values,
          params: {
            content_id: application_id,
          },
          globalOptions: {
            returnResult: true,
            content: true,
            endPath: true,
            returnPath: ApiEndpoint.CONFIGURATION,
            endpoint: WorkspaceMemberEndpoint.MEMBER,
          },
        }),
      );
    },
    [application_id, formType],
  );

  const handleStatus = (row) => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Resend }));
    dispatch(
      addServiceModal({
        name: ServiceModalName.ConfirmModal,
        payload: {
          label: {
            title: `${tModal('resentInvitation')}?`,
            icon: <ShieldPlusIcon className="size-10 text-blue-400" />,
            text: tModal('aNewInvitationWillBeSentTo', { user: row?.name }),
            confirmButton: tCommon('button.invite'),
          },
          option: {
            modalType: StatusType.Info,
            modalTable: {
              formType,
            },
            modalForm: {
              preventDefault: {
                callback: true,
              },
            },
          },
          callbackFunction: {
            submit: () => {
              dispatch(removeServiceModal(ServiceModalName.ConfirmModal));
              dispatch(
                submitFormRequest({
                  formType,
                  formData: { email: row?.email },
                  params: {
                    content_id: application_id,
                  },
                  globalOptions: {
                    endPath: true,
                    returnPath: ApiEndpoint.CONFIGURATION,
                    endpoint: WorkspaceMemberEndpoint.RESEND_INVITATION,
                  },
                }),
              );
            },
          },
        },
      }),
    );
  };

  const handleCancel = () => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Idle }));
  };

  const modalPayload = useMemo(
    () => ({
      name: ServiceModalName.FormModal,
      payload: {
        label: {
          title: tModal('addNewMember'),
          text: tDescription('addUserToWorkspaceByEmailAddress'),
          confirmButton: tButton('sendInvitation'),
        },
        option: {
          modalForm: { initialValues, inputList, validationSchema: workspaceMemberSchema },
          modalTable: { formType },
          button: { loading: false },
          size: 'md',
        },
        callbackFunction: {
          submit: handleSubmit,
          cancel: handleCancel,
        },
      } as ServiceModalPayload,
    }),
    [tCommon, inputList],
  );

  const handleModal = useCallback(() => {
    dispatch(addServiceModal(modalPayload));
  }, [modalPayload]);

  const memberListTable = useMemo(() => getWsConfigMemberColumns({ handleStatus, isOwner }), [tTabs, isOwner]);

  const workspaceMemberFormHandlers = useFormHandlers({
    formType,
    handlerOptions: [
      {
        type: FormActionType.Create,
        function: handleModal,
        display: isOwner,
        label: tCommon('button.addMember'),
      },
      {
        type: FormActionType.Remove,
        display: isOwner,
        function: (rowData) => {
          dispatch(
            addServiceModal({
              name: ServiceModalName.ConfirmModal,
              payload: {
                label: {
                  title: tModal('removeAccessTitle', { name: rowData.name }),
                  icon: <ShieldPlusIcon className="size-10 text-blue-400" />,
                  text: tDescription('confirmDeactivate', { name: rowData.name }),
                  confirmButton: tCommon('button.deactivate'),
                },
                option: {
                  modalType: StatusType.Delete,
                  modalTable: {
                    formType,
                  },
                },
                callbackFunction: {
                  submit: () => {
                    dispatch(
                      deleteFormContent({
                        formType,
                        formData: { email: rowData.email },
                        params: {
                          content_id: application_id,
                        },
                        globalOptions: {
                          returnResult: true,
                          content: true,
                          endPath: true,
                          returnPath: ApiEndpoint.CONFIGURATION,
                          endpoint: WorkspaceMemberEndpoint.MEMBER,
                        },
                      }),
                    );
                  },
                  cancel: handleCancel,
                },
              },
            }),
          );
        },
      },
    ],
  });

  return (
    <FormListLayout
      label={{
        title: tTabs('memberList'),
      }}
      tableData={{
        columns: memberListTable,
        actionHandlers: workspaceMemberFormHandlers,
        customTable: workspaceMember,
        formType,
      }}
    />
  );
};
