import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '@components';
import { CREATE_USER_ACCOUNT_FORM_CONFIG, CreateUserAccountValidationSchema, ROUTES } from '@constants';
import { FormActionType, FormType, Translation, UserEndpoint } from '@enums';
import { useAppDispatch, useErrorFormInner } from '@hooks';
import { submitFormRequest, updateFormProcedure, useFormError, useFormSubmissionStatus } from '@store';
import type { CreateUserAccountFormValues } from '@types';
import { LoginButtonGroup } from '../subComponents';
import { LoginFormFieldWrapper } from '../subComponents/loginFormFieldWrapper';

export const CreateUserAccountForm = ({ recaptchaRef }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formType = FormType.User;
  const createUserAccountFormMetaData = { formType, endpoint: UserEndpoint.REGISTER };

  const { t: tSignIn } = useTranslation(Translation.SignIn);
  const { t: tButton } = useTranslation(Translation.SignIn, { keyPrefix: 'button' });

  const isFormSubmitting = useFormSubmissionStatus(createUserAccountFormMetaData);
  const isFormError = useFormError(createUserAccountFormMetaData);

  const { fields, initialValues } = CREATE_USER_ACCOUNT_FORM_CONFIG;

  const handleSubmit = useCallback(
    (values: CreateUserAccountFormValues) => {
      dispatch(updateFormProcedure({ formType, procedure: FormActionType.Create }));
      dispatch(
        submitFormRequest({
          formType,
          formData: values,
          globalOptions: {
            recaptcha: true,
            endPath: true,
            endpoint: UserEndpoint.REGISTER,
          },
          callbackFunction: {
            refetch: () => navigate(ROUTES.login),
          },
          ref: {
            recaptcha: recaptchaRef,
          },
        }),
      );
    },
    [formType, dispatch],
  );

  const CreateUserAccountErrorInner = () => {
    useErrorFormInner({ submitError: isFormError, isSubmitting: isFormSubmitting });
    return null;
  };

  return (
    <Formik
      initialValues={initialValues as CreateUserAccountFormValues}
      validationSchema={CreateUserAccountValidationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="space-y-5 pt-2">
          <CreateUserAccountErrorInner />
          {fields.map((field) => (
            <LoginFormFieldWrapper
              key={field.name}
              field={{
                ...field,
                label: (
                  <Text $level={5} $customizeColor className="text-slate-400">
                    {tSignIn(String(field.label))}
                  </Text>
                ),
                fullWidth: true,
                customizeColor: true,
                withoutRing: true,
                smallFont: true,
                textInputClassName:
                  'bg-theme-neutral-main text-white rounded-lg focus:ring-2 focus:ring-theme-primary transition-all duration-300',
              }}
              translation={tSignIn}
            />
          ))}
          <LoginButtonGroup
            label={{ confirmButton: tButton('createAccount') }}
            formParams={createUserAccountFormMetaData}
            hasNavigate
          />
        </Form>
      )}
    </Formik>
  );
};
