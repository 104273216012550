import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { GlobalLoader, Text } from '@components';
import { ROUTES } from '@constants';
import { FormActionType, FormType, Translation, UserEndpoint } from '@enums';
import { useAppDispatch } from '@hooks';
import { submitFormRequest, updateFormProcedure } from '@store';

export const VerifyEmail: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formType = FormType.User;

  const { t: tTabs } = useTranslation(Translation.SignIn, { keyPrefix: 'tab' });

  const [searchParams] = useSearchParams();

  const key = searchParams.get('key');
  const code = searchParams.get('code');

  useEffect(() => {
    if (key && code) {
      dispatch(updateFormProcedure({ formType, procedure: FormActionType.Verification }));
      dispatch(
        submitFormRequest({
          formType,
          params: { email: key, token: code },
          globalOptions: {
            endPath: true,
            endpoint: UserEndpoint.VERIFY_EMAIL,
            refetchAnywhere: true,
          },
          callbackFunction: {
            refetch: () => navigate(ROUTES.login),
          },
        }),
      );
    }
    if (!key || !code) {
      navigate(ROUTES.login);
    }
  }, [key, code]);

  return (
    <div className="space-y-10 pb-4 place-items-center">
      <Text $level={2} color="text-white">
        {tTabs('verifyingYourEmailAddress')}
      </Text>
      <GlobalLoader color="white" />
    </div>
  );
};
