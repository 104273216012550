import type { LoginFormConfig } from '@core/types/forms/user/CreateUserAccountForm';

export const CREATE_NEW_PASSWORD_FORM_CONFIG: LoginFormConfig = {
  initialValues: {
    password: '',
    confirm_password: '',
  },
  fields: [
    {
      name: 'password',
      label: 'form.password',
      placeholder: 'form.enterYourPassword',
      isAnimated: true,
    },
    {
      name: 'confirm_password',
      label: 'form.confirmPassword',
      placeholder: 'form.enterYourConfirmPassword',
      isAnimated: true,
    },
  ],
} as const;
