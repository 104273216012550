export const ValidationRegExp = {
  latinLettersNumbersCharactersReg: /^[A-Za-zА0-9\s!№$*?()[\]{}'";:/<>,._-]+$/,
  latinLettersNumbersCharactersWithoutSpacesReg: /^[A-Za-zА0-9!№$*?()[\]{}'";:/<>,._-]+$/,
  latinLettersNumbersSpecialCharactersReg: /^[A-Za-zА0-9\s'.-_]+$/,
  latinLettersNumbersSpecialCharactersCommaReg: /^[A-Za-zА0-9\s'.,-_]+$/,
  latinLettersNumbersAllSpecialCharactersReg: /[A-Za-zА0-9№!$?*()[\]{}'";:/<>,._-]+$|^[\w\s]+$|^$/,
  latinLettersNumbersSpecialSymbolsReg: /^[A-Za-zА0-9\s-"',№]+$/,
  latinLettersSpecialCharactersReg: /^[A-Za-zА\s-']+$/,
  latinLettersSpecialSymbolsReg: /^[A-Za-zА\s-.,'/]+$/,
  latinLettersNumeralsReg: /^[A-Za-z0-9]+$/,
  numbersReg: /^[0-9]+$/,
  positiveNegativeNumbersReg: /^-?\d*$/,
  positiveNegativeNumbersFloatReg: /^-?\d*(\.\d*)?$/,
  numbersDecimalsReg: /^\d+(\.\d+|,\d+)?$/,
  numbersRequiredDecimalsReg: /^\d+(\.\d{1,2})$/,
  numbersSpecialCharactersReg: /^[А0-9\s'.-]+$/,
  latinLetters: /^[A-Za-z]+$/,
  latinLettersNumbersCharacters: /^[A-Za-zА0-9\s!№$*?()[\]{}'";:/<>,._-]+$/,
  latinLettersNumbersCharactersWithoutSpaces: /^[A-Za-zА0-9!№$*?()[\]{}'";:/<>,._-]+$/,
  latinLettersNumbersSpecialCharacters: /^[A-Za-zА0-9\s'.-_]+$/,
  latinLettersNumbersSpecialCharactersComma: /^[A-Za-zА0-9\s'.,-_]+$/,
  latinLettersNumbersAllSpecialCharacters: /[A-Za-zА0-9№!$?*()[\]{}'";:/<>,._-]+$|^[\w\s]+$|^$/,
  latinLettersNumbersSpecialSymbols: /^[A-Za-zА0-9\s-"',№]+$/,
  latinLettersSpecialCharacters: /^[A-Za-zА\s-']+$/,
  latinLettersSpecialSymbols: /^[A-Za-zА\s-.,'/]+$/,
  latinLettersNumerals: /^[A-Za-z0-9]+$/,
  numbers: /^[0-9]+$/,
  positiveNegativeNumbers: /^-?\d*$/,
  positiveNegativeNumbersFloat: /^-?\d*(\.\d*)?$/,
  numbersDecimals: /^\d+(\.\d+|,\d+)?$/,
  numbersRequiredDecimals: /^\d+(\.\d{1,2})$/,
  numbersSpecialCharacters: /^[А0-9\s'.-]+$/,

  // Additional common patterns
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  ipAddress: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  domain: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/,
  url: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  phone: /^\+?[\d\s-]{10,}$/,
  date: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
  time: /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/,
  password: /^[A-Za-z\d!@#$%^&*()[\]{};':",.<>/?-]{8,}$/,
} as const;
