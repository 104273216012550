export const ROUTES = {
  login: '/',
  resetPassword: '/reset_password',
  newPassword: '/new_password',
  registration: '/registration',
  maintenance: '/maintenance',
  verifyEmail: '/verify-email',
  cdn: '/cdn',
  notfound: '/404',
  homepage: '/homepage',
  profileSettings: '/profile_settings',
  workspace: {
    main: '/workspace',
    settings: '/settings',
    invite: '/workspace/:workspaceId/invite',
  },
  configuration: {
    main: 'configuration',
    dashboard: 'dashboard',
    domain: 'domain',
    origins: 'origins',
    security: 'security',
    ssl: 'ssl',
    waf: {
      main: 'waf',
      customRules: 'customRules',
    },
    pagerules: 'pagerules',
    cache: 'cache',
    appConfiguration: 'appConfiguration',
  },
};
