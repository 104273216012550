import classNames from 'classnames';
import clsx from 'clsx';
import { Field } from 'formik';
import { memo, PropsWithChildren } from 'react';

import {
  ActionTableInput,
  ConditionTableInput,
  MultipleInput,
  MultipleSelectCard,
  MultipleSelectInput,
  RadioTableInput,
  Responsive,
  SelectInput,
  Text,
  TextAreaInput,
  TextInput,
  ToggleButton,
} from '@components';

import { columnDirection, columnSpace, InputMod, InputType } from '@enums';
import { FormFieldProps } from '@types';

export const FormField = ({
  withoutLabel,
  color = 'text-gray-700',
  inputType = InputType.Text,
  direction = columnDirection.Column,
  description = '',
  fullWidth,
  name,
  onBlur,
  emptyData,
  isError = false,
  clearFunction,
  format,
  disabled,
  centerItem = false,
  withoutClear = false,
  smallFont,
  adornmentWithoutM,
  columnPercentage = columnSpace.TwoOverFive,
  className = '',
  textInputClassName = '',
  defaultValue = '',
  onChange,
  children,
  inputMod,
  customWidth,
  uploadLabel,
  value,
  loading,
  adornment,
  withoutPadding,
  customizeColor,
  disabledSearch = false,
  options,
  min,
  max,
  autoComplete,
  adornmentPosition,
  nestedName = '',
  rows,
  placeholder,
  withoutRing,
  label,
  hints,
  besideTitle,
  type,
  required,
  maxField,
  mobileTextInput = 'flex-col',
  withoutChip,
  maxSelections,
  nestedComponent,
  withoutErrorMessage,
}: PropsWithChildren<FormFieldProps>) => {
  const getInput = () => {
    switch (inputType) {
      case InputType.Select:
        return (
          <Field
            name={name}
            smallFont={smallFont}
            label={label}
            disabled={disabled}
            format={format}
            className={textInputClassName}
            customWidth={customWidth}
            onChange={onChange}
            withoutRing={withoutRing}
            value={value}
            withoutPadding={withoutPadding}
            loading={loading}
            placeholder={placeholder}
            inputMod={inputMod}
            fullWidth={fullWidth}
            options={options}
            as={SelectInput}
          />
        );
      case InputType.Area:
        return (
          <Field
            name={name}
            smallFont={smallFont}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            uploadLabel={uploadLabel}
            rows={rows}
            className={textInputClassName}
            fullWidth={fullWidth}
            as={TextAreaInput}
          />
        );
      case InputType.Switch:
        return (
          <Field
            disabled={disabled}
            inputMod={inputMod}
            label={label}
            className={textInputClassName}
            onChange={onChange}
            name={name}
            description={description}
            as={ToggleButton}
          />
        );
      case InputType.MultipleSelect:
        return (
          <Field name={name} format={format} placeholder={placeholder} fullWidth={fullWidth} as={MultipleSelectCard} />
        );
      case InputType.MultipleSelectInput:
        return (
          <Field
            name={name}
            smallFont={smallFont}
            label={label}
            disabled={disabled}
            format={format}
            className={textInputClassName}
            customWidth={customWidth}
            onChange={onChange}
            withoutRing={withoutRing}
            value={value}
            withoutPadding={withoutPadding}
            loading={loading}
            placeholder={placeholder}
            inputMod={inputMod}
            fullWidth={fullWidth}
            disabledSearch={disabledSearch}
            options={options}
            withoutChip={withoutChip}
            maxSelections={maxSelections}
            as={MultipleSelectInput}
          />
        );
      case InputType.Table:
        if (name === 'actions') {
          return (
            <ActionTableInput
              className={textInputClassName}
              format={format}
              name={name}
              columnPercentage={columnPercentage}
              emptyData={emptyData}
            />
          );
        } else {
          return (
            <ConditionTableInput
              className={textInputClassName}
              format={format}
              name={name}
              columnPercentage={columnPercentage}
              emptyData={emptyData}
            />
          );
        }

      case InputType.Radio:
        return <RadioTableInput name={name} />;
      case InputType.Custom:
        return children;
      case InputType.MultipleInput:
        return (
          <Field
            name={name}
            smallFont={smallFont}
            disabled={disabled}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            withoutRing={withoutRing}
            className={textInputClassName}
            min={min}
            max={max}
            customizeColor={customizeColor}
            defaultValue={defaultValue}
            emptyData={emptyData}
            nestedName={nestedName}
            hints={hints}
            label={label}
            value={value}
            fullWidth={fullWidth}
            inputMod={inputMod}
            withoutClear={withoutClear}
            maxField={maxField}
            nestedComponent={nestedComponent}
            withoutErrorMessage={withoutErrorMessage}
            as={MultipleInput}
          />
        );
      default:
        return (
          <Field
            name={name}
            smallFont={smallFont}
            disabled={disabled}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            withoutRing={withoutRing}
            clearFunction={clearFunction}
            adornmentPosition={adornmentPosition}
            onBlur={onBlur}
            className={textInputClassName}
            customizeColor={customizeColor}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            hints={hints}
            max={max}
            min={min}
            loading={loading}
            label={label}
            adornment={adornment}
            adornmentWithoutM={adornmentWithoutM}
            value={value}
            isError={isError}
            fullWidth={fullWidth}
            inputMod={inputMod}
            withoutClear={withoutClear}
            withoutErrorMessage={withoutErrorMessage}
            as={TextInput}
          />
        );
    }
  };
  const isColumn = direction === columnDirection.Column;
  const isRow = direction === columnDirection.Row;
  const isColumnRow = direction === columnDirection.ColumnRow;

  const isTwoOverFive = columnPercentage === columnSpace.TwoOverFive;
  const isThreeOverFive = columnPercentage === columnSpace.ThreeOverFive;
  const isHalf = columnPercentage === columnSpace.Half;

  const isTable = inputType === InputType.Table;
  const isFilled = inputMod === InputMod.Filled;

  const dynamicContainerClasses = classNames('gap-3', {
    'w-full': fullWidth,
    'flex ': isRow || isColumnRow,
    'grid grid-cols-1': isColumn && !centerItem && !isHalf,
    [className]: className,
  });

  const labelContainerClasses = classNames('pr-10 flex flex-col space-y-3', {
    'w-2/5': (isRow || isColumnRow || isTable) && isTwoOverFive && !withoutLabel,
    'w-3/5': (isRow || isColumnRow || isTable) && isThreeOverFive && !withoutLabel,
    'w-2/4': (isRow || isColumnRow || isTable) && isHalf && !withoutLabel,
  });

  const InputContainerClasses = classNames('flex flex-col gap-y-3', {
    'w-full': fullWidth && !(isRow || isColumnRow),
    'w-3/5': (isRow || isColumnRow || isTable) && isTwoOverFive && !withoutLabel,
    'w-2/5': (isRow || isColumnRow || isTable) && isThreeOverFive && !withoutLabel,
    'w-2/4': (isRow || isColumnRow || isTable) && isHalf && !withoutLabel,
    'items-center justify-center': centerItem,
  });

  const RenderLabel = memo(({ value, level = 5, required = false, color, renderLabelClassname = '' }: any) =>
    value && typeof value === 'string' ? (
      <Text $level={level} color={color} className={clsx(renderLabelClassname, 'font-normal')}>
        {`${value} ${required ? '*' : ''}`}
      </Text>
    ) : (
      value
    ),
  );

  if (!name) return;
  return (
    <div className={dynamicContainerClasses}>
      <Responsive showAbove="sm">
        {withoutLabel && isRow && isTwoOverFive && <div className="w-2/5"></div>}
        {!withoutLabel && (
          <div className={labelContainerClasses}>
            <RenderLabel value={label} required={required} color={color} renderLabelClassname="text-left leading-6" />
            {isColumnRow && !isFilled && (
              <RenderLabel
                value={description}
                required={required}
                color={color}
                renderLabelClassname="text-justify leading-6"
              />
            )}
          </div>
        )}
        <div className={InputContainerClasses}>
          {getInput()}

          {!isColumnRow && !isFilled && (
            <RenderLabel
              value={description}
              required={required}
              color={color}
              renderLabelClassname="text-justify leading-6"
            />
          )}
        </div>
      </Responsive>
      <Responsive showBelow="sm">
        <div className={`${mobileTextInput} flex flex-1 gap-3`}>
          {!withoutLabel && besideTitle && (
            <div className="flex justify-between items-center gap-3">
              <RenderLabel value={label} required={required} color={color} renderLabelClassname="text-left leading-6" />
              {getInput()}
            </div>
          )}

          {!isFilled && !besideTitle && !withoutLabel && (
            <RenderLabel
              value={label}
              required={required}
              color={color}
              renderLabelClassname="text-justify leading-6"
            />
          )}
          {!besideTitle && <div className={mobileTextInput}>{getInput()}</div>}
        </div>
      </Responsive>
    </div>
  );
};
