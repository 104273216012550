import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode, forwardRef, useMemo, useRef } from 'react';
import { Instance } from 'tippy.js';

import { InitLoader, Text, colors } from '@components';
import { InputType, VariantType } from '@enums';
import { validateValueEqual } from '@utils';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: VariantType;
  icon?: ReactNode;
  iconWithoutM?: boolean;
  withoutPadding?: boolean;
  withoutCenter?: boolean;
  withoutHover?: boolean;
  customizeColor?: boolean;
  withoutScale?: boolean;
  hoverEffect?: boolean;
  withoutCursor?: boolean;
  smallFont?: boolean;
  selected?: boolean;
  customFont?: boolean;
  fullWidth?: boolean;
  withoutEllipsis?: boolean;
  hint?: ReactNode;
  hintPlacement?: 'top' | 'bottom' | 'left' | 'right';
  rounded?: string;
  direction?: 'left' | 'right';
  predefinedColor?: keyof typeof colors;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      disabled = false,
      loading = false,
      direction = 'left',
      variant = VariantType.Contained,
      icon,
      className = '',
      children,
      iconWithoutM = false,
      selected = false,
      hoverEffect = false,
      withoutPadding = false,
      withoutCenter = false,
      withoutHover = false,
      withoutScale = false,
      smallFont = false,
      withoutCursor = false,
      customFont = false,
      customizeColor = false,
      fullWidth = false,
      withoutEllipsis = false,
      hint,
      hintPlacement = 'top',
      rounded = 'rounded-lg',
      predefinedColor = '',
      onClick,
      ...props
    },
    ref,
  ) => {
    const isContained = validateValueEqual<VariantType>(variant, VariantType.Contained);
    const isOutlined = validateValueEqual<VariantType>(variant, VariantType.Outlined);
    const isIcon = validateValueEqual<VariantType>(variant, VariantType.Icon);
    const isTransparent = validateValueEqual<VariantType>(variant, VariantType.Transparent);
    const tippyInstance = useRef<Instance | null>(null);
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      tippyInstance.current?.hide();
      onClick?.(e);
    };
    const dynamicClasses = useMemo(
      () =>
        classNames('flex focus:outline-none outline-none', {
          'text-gray-400': disabled && InputType.Filled,
          'hover:bg-black hover:bg-opacity-5 transition-all duration-500 ease-in-out': hoverEffect,
          'hover:opacity-85 hover:scale-95': !disabled && !withoutScale && !hoverEffect && !loading,
          'px-3.5 py-3': !withoutPadding && !isIcon,
          'ring-0 p-2': withoutPadding && isIcon,
          'text-xs': !smallFont && !customFont,
          'text-us': smallFont && !customFont,
          'items-center justify-center': !withoutCenter,
          'hover:cursor-pointer': !withoutCursor && !disabled && !loading,
          'hover:cursor-not-allowed': !withoutCursor && (disabled || loading),
          'bg-opacity-80': loading && !customizeColor,
          'text-opacity-40': (disabled && !customizeColor) || (!customizeColor && loading && isTransparent),
          'bg-black bg-opacity-5 text-blue-400': selected,
          'bg-theme-primary-main text-white': !customizeColor && !disabled && isContained,
          'bg-opacity-40': customizeColor && disabled && isContained,
          'bg-gray-200 text-theme-text-disabled': !customizeColor && disabled && (isContained || isOutlined),
          'ring-1 ring-inset ring-theme-primary-main text-theme-primary-main hover:bg-blue-50 hover:bg-opacity-50':
            !customizeColor && !disabled && isOutlined,
          'ring-opacity-40 text-opacity-40': !customizeColor && loading && isOutlined,
          'w-full': fullWidth,
          'ellipsis-text': !withoutEllipsis,
          [colors[predefinedColor]]: predefinedColor,
          [rounded]: rounded,
          [className]: className,
        }),
      [
        className,
        customFont,
        customizeColor,
        disabled,
        fullWidth,
        hoverEffect,
        isContained,
        isIcon,
        isOutlined,
        isTransparent,
        loading,
        rounded,
        selected,
        smallFont,
        withoutCenter,
        withoutCursor,
        withoutHover,
        withoutPadding,
        withoutScale,
        predefinedColor,
      ],
    );

    const buttonElement = (
      <button
        ref={ref}
        type={type}
        className={dynamicClasses}
        disabled={disabled || loading}
        aria-disabled={disabled || loading ? 'true' : undefined}
        aria-busy={loading ? 'true' : undefined}
        onClick={handleClick}
        {...props}
      >
        {loading && <InitLoader size={15} dot={6} color={isOutlined ? 'rgb(26 131 187' : 'white'} className="mr-3" />}
        {icon && direction === 'left' && !loading && <div className={icon && iconWithoutM ? '' : 'mr-3'}>{icon}</div>}
        {typeof children === 'string' ? (
          <Text $level={6} $customizeColor>
            {children}
          </Text>
        ) : (
          children
        )}
        {icon && direction === 'right' && !loading && <div className={icon && iconWithoutM ? '' : 'ml-3'}>{icon}</div>}
      </button>
    );
    if (!hint) return buttonElement;
    return (
      <Tippy
        disabled={!hint}
        placement={hintPlacement}
        content={hint}
        animation="shift-away"
        arrow={true}
        theme="custom"
        className="text-white font-normal px-1 py-0.5"
        duration={[200, 0]}
        offset={[0, 10]}
        delay={[75, 0]}
        appendTo={() => document.body}
        trigger="mouseenter"
        onCreate={(instance) => {
          tippyInstance.current = instance;
        }}
      >
        {buttonElement}
      </Tippy>
    );
  },
);
