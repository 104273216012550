import classNames from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { memo, PropsWithChildren, ReactNode, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ActionButtonGroup, Column, DraggableItem, DraggableTable, EmptyObject, Subheading, Text } from '@components';
import { Dictionaries, FormType } from '@enums';
import { FormHandlers, useAppDispatch } from '@hooks';
import {
  updateFormPages,
  useFormFilter,
  useFormList,
  useFormLoadingStatus,
  useFormPagination,
  useFormProcedure,
  useFormSubmissionStatus,
} from '@store';

type FilterOption = Readonly<{
  label: string;
  value: string;
}>;

type FilterConfig = Readonly<{
  options?: () => FilterOption[];
  functions: (values: Record<string, unknown>) => void;
  dictionary?: Dictionaries;
}>;

type FilterValues = {
  search: string;
  status: string;
};

interface FormListLayoutProps<T> {
  label: {
    title: string | ReactNode;
    subTitle?: string | ReactNode;
  };
  filters?: Readonly<Record<string, FilterConfig>>;
  tableData: Readonly<{
    columns?: Column[];
    actionHandlers: FormHandlers<DraggableItem>;
    isDraggable?: boolean;
    hasChildren?: boolean;
    customTable?: any[];
    withoutCard?: boolean;
    isLoading?: boolean;
    customHeader?: boolean;
    formType: FormType;
    className?: string;
    onChangePage?: () => void;
  }>;
}

export const FormListLayout = memo(
  <T extends EmptyObject<T>>({
    label,
    filters,
    children,
    tableData: {
      columns,
      hasChildren = false,
      withoutCard = false,
      isDraggable = false,
      isLoading = false,
      customTable,
      customHeader = false,
      className = '',
      actionHandlers,
      formType = FormType.Empty,
      onChangePage,
    },
  }: PropsWithChildren<FormListLayoutProps<T>>) => {
    const dispatch = useAppDispatch();
    const { id: application_id } = useParams();

    const formList = useFormList({ formType });
    const formPagination = useFormPagination({ formType });
    const { searchTerm, filterCriteria } = useFormFilter(formType);

    const formLoading = useFormLoadingStatus({ formType }) || isLoading;
    const formSubmitting = useFormSubmissionStatus({ formType });
    const formProcedure = useFormProcedure(formType);

    const defaultFilterValues: FilterValues = {
      search: '',
      status: 'all',
    };

    const getStatusValue = useCallback((content: readonly FilterOption[], filterKey: string): string => {
      const found = content.find((item) => item.label === filterKey);
      return found?.value ?? defaultFilterValues.status;
    }, []);
    const filteredData = useMemo(() => {
      if (filterCriteria.some((filter) => filter.value === 'all')) {
        return formList;
      }
      return formList.filter((item) => filterCriteria.some((filter) => filter.value === item.status));
    }, [formList, filterCriteria]);

    const initialValues = useMemo(
      () => ({
        search: searchTerm ?? '',
        status: getStatusValue(filterCriteria, 'status') ?? 'all',
      }),
      [filterCriteria, getStatusValue, searchTerm],
    );

    const handlePageChanges = useCallback((page: number) => {
      onChangePage ??
        dispatch(
          updateFormPages({
            formType,
            page,
            params: { application_id },
          }),
        );
    }, []);

    const handleSubmit = useCallback(
      (values: FilterValues, { setSubmitting }: FormikHelpers<FilterValues>) => {
        filters?.['search'].functions(values);
        setSubmitting(false);
      },
      [filters],
    );

    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {() => (
          <div
            className={classNames('w-full h-fit flex flex-col', {
              'px-8 py-7 bg-white rounded-2xl shadow-card gap-8 md:gap-10': !withoutCard,
              [className]: className,
            })}
          >
            <div className="flex gap-4 flex-col md:flex-row md:justify-between">
              {!customHeader ? (
                <div className="flex flex-col gap-3">
                  <Subheading level={3}>{label.title}</Subheading>
                  {!!label.subTitle &&
                    (typeof label.subTitle === 'string' ? (
                      <Text $level={5} $customizeColor className="font-medium text-slate-500">
                        {label.subTitle}
                      </Text>
                    ) : (
                      label.subTitle
                    ))}
                </div>
              ) : (
                label.title
              )}

              <ActionButtonGroup
                actionHandlers={actionHandlers}
                loading={formLoading || formSubmitting}
                procedure={formProcedure}
              />
            </div>
            {hasChildren && children}
            {columns && (
              <DraggableTable
                rows={customTable ?? filteredData}
                columns={columns}
                formType={formType}
                isDataLoading={formLoading}
                onChangePage={handlePageChanges}
                isDraggable={isDraggable}
                actionHandlers={actionHandlers}
                pagination={formPagination}
              />
            )}
          </div>
        )}
      </Formik>
    );
  },
);

FormListLayout.displayName = 'FormListLayout';
