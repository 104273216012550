export enum CdnDashboardFilter {
  Time = 'interval',
  Domain = 'domain_id',
}

export enum FormType {
  Empty = 'empty',
  Cdn = 'cdn',
  User = 'user',
  Workspace = 'workspace',
  WorkspaceConfiguration = 'workspace_configuration',
  CdnDomain = 'cdn_domain',
  CdnOrigin = 'cdn_origin',
  CdnRoute = 'cdn_route',
  CdnSSL = 'cdn_ssl',
  CdnWAF = 'cdn_waf',
  CdnPageRules = 'cdn_page_rules',
  CdnSecurity = 'cdn_security',
  CdnCache = 'cdn_cache',
  CdnDashboard = 'cdn_dashboard',
  CdnApplicationConfiguration = 'cdn_app_configuration',
}

export enum FormActionType {
  Idle = 'IDLE',
  Create = 'CREATE',
  Edit = 'EDIT',
  Purge = 'PURGE',
  View = 'VIEW',
  Delete = 'DELETE',
  Save = 'SAVE',
  Custom = 'CUSTOM',
  Verification = 'VERIFICATION',
  Priority = 'PRIORITY',
  Check = 'CHECK',
  Remove = 'REMOVE',
  Transfer = 'TRANSFER',
  Resend = 'RESEND',
  Accept = 'ACCEPT',
}
