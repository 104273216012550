import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApiEndpoint, FormActionType, FormType, InputType, ServiceModalName, Translation } from '@enums';
import { useAppDispatch } from '@hooks';
import { addServiceModal, getWorkspaceOptions, submitFormRequest, updateFormProcedure } from '@store';

import { workspaceOverviewFormSchema } from '@constants';
import type { ServiceModalPayload } from '@types';
import { WorkspaceResponse } from 'types/api';
import { WsConfigCard } from '../components';

interface WsConfigOverviewProps {
  workspaceDetails: WorkspaceResponse;
}

export const WsConfigOverview: React.FC<WsConfigOverviewProps> = ({ workspaceDetails }) => {
  const { id: application_id } = useParams();
  const dispatch = useAppDispatch();

  const formType = FormType.Workspace;

  const { t: tCommon } = useTranslation(Translation.Common, { keyPrefix: 'button' });
  const { t: tModal } = useTranslation(Translation.Workspace, { keyPrefix: 'modals' });
  const { t: tDescription } = useTranslation(Translation.Workspace, { keyPrefix: 'descriptions' });
  const { t: tForms } = useTranslation(Translation.Workspace, { keyPrefix: 'forms' });
  const { t: tTabs } = useTranslation(Translation.Workspace, { keyPrefix: 'tabs' });
  const { t: tButtons } = useTranslation(Translation.Workspace, { keyPrefix: 'buttons' });

  const workspaceOverviewSchema = useMemo(() => workspaceOverviewFormSchema(), [tCommon]);

  const configItems = [
    {
      left: tForms('workspaceName'),
      right: workspaceDetails?.name,
    },
    {
      left: tForms('memberCount'),
      right: workspaceDetails?.permissions?.length,
    },
  ];

  const inputList = useMemo(
    () => [
      {
        name: 'name',
        defaultValue: '',
        inputType: InputType.Text,
        placeholder: tForms('chooseAUniqueNameToIdentifyYourWorkspace'),
        label: tForms('workspaceName'),
      },
    ],
    [workspaceDetails, tCommon],
  );

  const initialValues = useMemo(
    () => ({
      name: workspaceDetails?.name ?? '',
    }),
    [workspaceDetails, tCommon],
  );

  const handleSubmit = useCallback((values) => {
    dispatch(
      submitFormRequest({
        formType,
        formData: values,
        params: {
          content_id: application_id,
        },
        globalOptions: {
          returnResult: true,
          content: true,
          endpoint: ApiEndpoint.CONFIGURATION,
        },
        callbackFunction: {
          refetch: getWorkspaceOptions(),
        },
      }),
    );
  }, []);

  const handleCancel = () => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Idle }));
  };

  const modalPayload = useMemo(
    () => ({
      name: ServiceModalName.FormModal,
      payload: {
        label: {
          title: tModal('modifyWorkspaceSettings'),
          text: tDescription('updateYourWorkspaceNameAndDetails'),
          confirmButton: tCommon('update'),
        },
        option: {
          modalForm: { initialValues, inputList, validationSchema: workspaceOverviewSchema },
          modalTable: { formType },
          button: { loading: false },
          size: 'md',
        },
        callbackFunction: {
          submit: handleSubmit,
          cancel: handleCancel,
        },
      } as ServiceModalPayload,
    }),
    [tCommon, inputList],
  );

  const handleModal = useCallback(() => {
    dispatch(updateFormProcedure({ formType, procedure: FormActionType.Edit }));
    dispatch(addServiceModal(modalPayload));
  }, [modalPayload]);

  return (
    <WsConfigCard
      label={{ title: tTabs('workspaceOverview'), confirmButton: tButtons('updateDetails') }}
      infoItems={configItems}
      callbackFunction={{
        ...(workspaceDetails?.is_owner && { click: handleModal }),
      }}
    />
  );
};
