import { Disclosure } from '@headlessui/react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DisclosureBody, DisclosureModal, FormField, FormModal } from '@components';
import { domainFormSchema } from '@constants';
import { Dictionaries, FormActionType, FormType, InputType, Translation, columnDirection, originType } from '@enums';
import { useAppDispatch } from '@hooks';
import {
  getCdnSummary,
  submitFormRequest,
  useCdnSummary,
  useFormContent,
  useFormProcedure,
  useFormSubmissionStatus,
} from '@store';
import { getOptionListFromCatalog, validateValueEqual } from '@utils';
import { DomainDataProps } from '../Domain';

interface DomainFormProps {
  open: boolean;
  onClose: () => void;
}

export const DomainModal = ({ open, onClose }: DomainFormProps) => {
  const dispatch = useAppDispatch();
  const { id: application_id } = useParams();

  const formType = FormType.CdnDomain;

  const currentForm = useFormContent({ formType });
  const currentProcedure = useFormProcedure(formType);
  const isSubmitting = useFormSubmissionStatus({ formType });
  const appConfigurationDetails = useCdnSummary();

  const { t: tButton } = useTranslation(Translation.Configuration, { keyPrefix: 'buttons.domain' });
  const { t: tForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.domain' });
  const { t: tRouteForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.route' });
  const { t: tOriginForm } = useTranslation(Translation.Configuration, { keyPrefix: 'forms.origin' });
  const { t: tModal } = useTranslation(Translation.Configuration, { keyPrefix: 'modals.domain' });
  const { t: tValidation } = useTranslation(Translation.Validation, { keyPrefix: 'format' });
  const { t: tTables } = useTranslation(Translation.Common, { keyPrefix: 'tables' });

  const isEdit = validateValueEqual(currentProcedure, FormActionType.Edit);

  const handleSubmit = (values: DomainDataProps) => {
    const { domain, name, origin, type, verify_ssl_cert, ...otherValues } = values;

    const formData = {
      domain,
      name,
      ...(origin && {
        type,
        origin,
        verify_ssl_cert: type === originType.HTTPS ? verify_ssl_cert : false,
        ...otherValues,
      }),
    };

    dispatch(
      submitFormRequest({
        formType,
        formData: isEdit ? formData : { application_id, ...formData },
        ...(isEdit && {
          params: {
            content_id: currentForm?.id,
          },
        }),
        globalOptions: { refetch_id: application_id },
        callbackFunction: { refetch: getCdnSummary(application_id) },
      }),
    );
  };

  const initialValues: DomainDataProps = {
    domain: currentForm?.domain ?? '',
    name: currentForm?.name ?? '',
    origin: currentForm?.origin ?? '',
    type: currentForm?.type ?? originType.HTTPS,
    connect_timeout: currentForm?.connect_timeout ?? 5,
    send_timeout: currentForm?.send_timeout ?? 5,
    read_timeout: currentForm?.read_timeout ?? 5,
    verify_ssl_cert: currentForm?.verify_ssl_cert ?? true,
  };

  if (!open) return;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={domainFormSchema({ allowWildCard: appConfigurationDetails?.allow_wildcard })}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors }) => {
        return (
          <FormModal
            formType={formType}
            title={tModal(isEdit ? 'editDomain' : 'addNewDomain')}
            onClose={onClose}
            description={tModal(isEdit ? 'editDomainDescription' : 'addNewDomainDescription')}
            size="lg"
            open={open}
            confirmButton={{ children: tButton(isEdit ? 'editDomain' : 'addDomain') }}
          >
            <FormField
              label={tForm('generalName')}
              isCorrect={touched.name && !errors.name}
              placeholder={tForm('enterYourName')}
              disabled={isSubmitting}
              name="name"
              direction={columnDirection.Row}
              smallFont
              required
            />
            <FormField
              label={tForm('domainName')}
              isCorrect={touched.domain && !errors.domain}
              placeholder={tForm('enterYourDomain')}
              direction={columnDirection.Row}
              disabled={isSubmitting}
              name="domain"
              hints={tValidation('domain')}
              smallFont
              required
            />
            <div className="mt-3">
              <Disclosure>
                <DisclosureModal
                  button={{
                    label: tForm('advanceOptions'),
                    defaultOpen: !!(errors?.origin || currentForm?.origin),
                  }}
                >
                  <DisclosureBody notForm>
                    <FormField
                      label={tOriginForm('originType')}
                      name="type"
                      inputType={InputType.Select}
                      direction={columnDirection.Row}
                      smallFont
                      placeholder={tOriginForm('selectYourOriginType')}
                      fullWidth
                      options={getOptionListFromCatalog(Dictionaries.OriginType)}
                    />
                    <FormField
                      label={tForm('origin')}
                      placeholder={tOriginForm('enterYourIpv4Origin')}
                      direction={columnDirection.Row}
                      disabled={isSubmitting}
                      hints={tValidation('ip')}
                      name="origin"
                      smallFont
                    />
                    <FormField
                      label={tRouteForm('connectionTimeOut')}
                      name="connect_timeout"
                      direction={columnDirection.Row}
                      smallFont
                      min={1}
                      max={60}
                      fullWidth
                      placeholder={'0'}
                      adornment={
                        <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                          {tTables('seconds')}
                        </article>
                      }
                      type="number"
                    />
                    <FormField
                      label={tRouteForm('sendTimeOut')}
                      name="send_timeout"
                      smallFont
                      min={1}
                      max={60}
                      fullWidth
                      placeholder={'0'}
                      direction={columnDirection.Row}
                      adornment={
                        <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                          {tTables('seconds')}
                        </article>
                      }
                      type="number"
                    />
                    <FormField
                      label={tRouteForm('readTimeOut')}
                      direction={columnDirection.Row}
                      smallFont
                      min={1}
                      max={1800}
                      fullWidth
                      name="read_timeout"
                      placeholder={'0'}
                      adornment={
                        <article className="text-us flex items-center justify-end text-center w-full text-slate-500">
                          {tTables('seconds')}
                        </article>
                      }
                      type="number"
                    />
                    {values.type === originType.HTTPS && (
                      <FormField
                        label={tOriginForm('verifySSL')}
                        name="verify_ssl_cert"
                        inputType={InputType.Switch}
                        smallFont
                        direction={columnDirection.Row}
                      />
                    )}
                  </DisclosureBody>
                </DisclosureModal>
              </Disclosure>
            </div>
          </FormModal>
        );
      }}
    </Formik>
  );
};
