import i18n from 'i18next';
import { object, type TestContext } from 'yup';

import { ValidationType } from '@enums';
import { validateValueEqual } from '@utils';
import { createFormField } from '@utils/system/getValidationMessages';

export const CreateUserAccountValidationSchema = object().shape({
  ...createFormField('firstname', {
    required: true,
    pattern: ValidationType.LATIN_ALL_SPECIAL,
  }),
  ...createFormField('lastname', {
    required: true,
    pattern: ValidationType.LATIN_ALL_SPECIAL,
  }),
  ...createFormField('username', {
    required: true,
    pattern: ValidationType.EMAIL,
  }),
  ...createFormField('password', {
    required: true,
    pattern: ValidationType.PASSWORD,
  }),
  ...createFormField('confirm_password', {
    required: true,
    custom: {
      validator: function (this: TestContext, value: string) {
        return validateValueEqual(this?.parent?.password, value, 'string');
      },
      message: i18n.t('validation:changePassword.passwordsMustMatch'),
    },
  }),
});
